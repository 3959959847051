import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { passwordMatchValidator } from '../../validators/password-match.validator';
import { IInvitedUser, IInvitedUserRegisterDto } from '@dominion/interfaces';
import { APP_VERSION } from 'apps/vox-fe/src/version';
import {
  passwordRequirementValidator,
  passwordErrorMessages,
} from '../../validators/password-requirement.validator';

@Component({
  selector: 'dominion-invite-registration',
  templateUrl: './invite-registration.component.html',
  styleUrls: ['./invite-registration.component.css'],
})
export class InviteRegistrationComponent implements OnInit {
  showPassword = false;
  inviteUserId: string;
  form: FormGroup;
  error: HttpErrorResponse;
  invitedUser: IInvitedUser | undefined;
  appVersion = APP_VERSION;

  @ViewChild('password') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('confirm') confirmInput: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
  ) {
    this.form = fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        department: ['', Validators.required],
        jobTitle: ['', Validators.required],
        password: ['', [Validators.required, passwordRequirementValidator()]],
        confirmPassword: [
          '',
          [Validators.required, passwordRequirementValidator()],
        ],
      },
      { validators: passwordMatchValidator },
    );
  }

  togglePassword() {
    if (this.showPassword) {
      this.showPassword = false;
      this.confirmInput.nativeElement.setAttribute('type', 'password');
      this.passwordInput.nativeElement.setAttribute('type', 'password');
    } else {
      this.showPassword = true;
      this.confirmInput.nativeElement.setAttribute('type', 'text');
      this.passwordInput.nativeElement.setAttribute('type', 'text');
    }
  }

  private buildDto(): IInvitedUserRegisterDto {
    const dto: IInvitedUserRegisterDto = {
      firstName: this.form.get('firstName')!.value,
      lastName: this.form.get('lastName')!.value,
      department: this.form.get('department')!.value,
      jobTitle: this.form.get('jobTitle')!.value,
      password: this.form.get('password')!.value,
      _id: this.inviteUserId,
    };
    return dto;
  }

  public register() {
    if (this.form.valid) {
      this.spinner.show('spin');
      const dto = this.buildDto();
      this.authService.registerInvitedUser(dto).subscribe({
        next: () => {
          this.registerSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('spin');
          this.error = err;
        },
      });
    }
    return;
  }

  private getInvitedUser() {
    if (this.inviteUserId) {
      this.authService.getInvitedUser(this.inviteUserId).subscribe({
        next: (user: IInvitedUser) => {
          this.invitedUser = user;
        },
        error: (err: HttpErrorResponse) => {
          this.error = err;
        },
      });
    }
  }

  private registerSuccess() {
    this.router.navigate(['/registration-success']);
    this.spinner.hide('spin');
    return;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.inviteUserId = params['id'];
    });
    this.getInvitedUser();
  }

  getPasswordErrorMessage() {
    const control = this.form.get('password');
    if (control?.hasError('required')) {
      return passwordErrorMessages['required'];
    }
    if (control?.hasError('passwordRequirements')) {
      const firstErrorKey = Object.keys(
        control.errors?.['passwordRequirements'],
      )[0] as keyof typeof passwordErrorMessages;
      return passwordErrorMessages[firstErrorKey];
    }
    return null;
  }
}
