import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'dominion-core-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './core-card.component.html',
  styleUrls: ['./core-card.component.css'],
})
export class CoreCardComponent {
  @HostBinding('class') class = 'flex flex-row grow';
  @Input() title: string;
  @Input() count: string | number;
}
