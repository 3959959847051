import {
  IsArray,
  IsDateString,
  IsIn,
  IsNotEmpty,
  IsObject,
  IsOptional,
  IsString,
  MaxLength,
  MinLength,
  ValidateNested,
} from 'class-validator';
import {
  IAssignDominionToCompanyDto,
  ICompanyCreateDto,
  ICompanyUpdateDto,
  IDMS,
  States,
  SubmoduleType,
  VendorAuthRef,
} from '@dominion/interfaces';
import { Type } from 'class-transformer';
import 'reflect-metadata';

const StateList: States[] = [
  'AB',
  'AL',
  'AK',
  'AZ',
  'AR',
  'BC',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MB',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NB',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NL',
  'NM',
  'NS',
  'NT',
  'NU',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'ON',
  'OR',
  'PA',
  'PE',
  'QC',
  'RI',
  'SC',
  'SD',
  'SK',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export class Address {
  @IsString()
  street: string;
  @IsString()
  @IsOptional()
  line2?: string;
  @IsString()
  city: string;
  @IsIn(StateList)
  state: States;
  @IsString()
  @MaxLength(5)
  @MinLength(5)
  zip: string;
}

export class CompanyCreateDto implements ICompanyCreateDto {
  @IsString()
  name: string;
  @IsObject()
  address: Address;
  @IsString()
  dms: IDMS | string;
  @IsDateString()
  @IsOptional()
  preferredInstallDate?: Date;
  @IsDateString()
  contractExpirationDate: Date;
  @IsOptional()
  @IsString()
  hubspotId?: string;
  @IsDateString()
  @IsOptional()
  goLiveDate?: Date;
}

export class CompanyUpdateDto implements ICompanyUpdateDto {
  @IsString()
  @IsOptional()
  name?: string;
  @IsObject()
  @IsOptional()
  address?: Address;
  @IsString()
  @IsOptional()
  dms?: IDMS | string;
  @IsDateString()
  @IsOptional()
  preferredInstallDate?: Date;
  @IsDateString()
  @IsOptional()
  contractExpirationDate?: Date;
  @IsDateString()
  @IsOptional()
  goLiveDate?: Date;
}

export class AssignDominionUserToCompanyDto
  implements IAssignDominionToCompanyDto
{
  @IsNotEmpty()
  @IsString()
  userId: string;
  @IsNotEmpty()
  @IsString()
  companyId: string;
}

export class GetCompanyWithSubmodule {
  @IsNotEmpty()
  @IsString()
  companyId: string;
  @IsNotEmpty()
  @IsString()
  moduleId: string;
  @IsString()
  @IsNotEmpty()
  @IsIn(['preliminary', 'maintenance', 'default'] satisfies SubmoduleType[])
  submoduleType: SubmoduleType;
}

export class AuthorizeVendorsDto {
  @IsString()
  @IsNotEmpty()
  companyId: string;
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VendorAuthRef)
  vendors: VendorAuthRef[];
}
