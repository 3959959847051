import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import {
  IAddress,
  IFileUploadReference,
  StateOptions,
} from '@dominion/interfaces';
import {
  DropdownOption,
  DropdownSearchComponent,
} from '../../shared/dropdown-search/dropdown-search.component';
import { SharedModule } from '../../shared/shared.module';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';
import { PopoverDirective } from '../../shared/directives/popover.directive';
import { PopoverTargetDirective } from '../../shared/directives/popover-target.directive';
import { AddressTextInput } from '../../shared/address-input/address-input.component';

@Component({
  selector: 'dominion-input-address',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
    AddressTextInput,
  ],
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.css'],
})
export class InputAddressComponent implements ResponseInput {
  @Input({ required: true }) data: IAddress;

  @Output() response: EventEmitter<IAddress> = new EventEmitter<IAddress>();

  addressForm: FormGroup;
  states = StateOptions;
  selectedState: DropdownOption | null;

  @ViewChild('zipHost', { read: PopoverHostDirective })
  zipHost: PopoverHostDirective;
  @ViewChild('stateHost', { read: PopoverHostDirective })
  stateHost: PopoverHostDirective;
  @ViewChild('cityHost', { read: PopoverHostDirective })
  cityHost: PopoverHostDirective;
  @ViewChild('streetHost', { read: PopoverHostDirective })
  streetHost: PopoverHostDirective;

  constructor(private fb: FormBuilder) {
    this.addressForm = this.fb.group({
      street: ['', Validators.required],
      line2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.setdata();
    }
  }

  setdata() {
    if (this.data) {
      this.addressForm.patchValue(this.data);
      const state = this.states.find(
        (state) => state.value === this.data.state,
      );
      if (state) {
        this.selectedState = state;
      }
    }
  }

  checkStreetErr() {
    const isInvalid =
      this.addressForm.get('street')!.invalid &&
      this.addressForm.get('street')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.streetHost.show();
    } else {
      this.streetHost.hide();
    }
  }

  checkCityErr() {
    const isInvalid =
      this.addressForm.get('city')!.invalid &&
      this.addressForm.get('city')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.cityHost.show();
    } else {
      this.cityHost.hide();
    }
  }

  getStateErr() {
    const isInvalid =
      this.addressForm.get('state')!.invalid &&
      this.addressForm.get('state')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.stateHost.show();
    } else {
      this.stateHost.hide();
    }
  }

  checkZipErr() {
    const isInvalid =
      this.addressForm.get('zip')!.invalid &&
      this.addressForm.get('zip')!.touched
        ? true
        : false;
    if (isInvalid) {
      this.zipHost.show();
    } else {
      this.zipHost.hide();
    }
  }

  stateSelected(selection: DropdownOption) {
    this.addressForm.get('state')!.setValue(selection.value);
    this.selectedState = selection;
  }

  stateClosed() {
    this.addressForm.get('state')!.markAsTouched();
    this.getStateErr();
  }

  submit() {
    this.response.emit(this.addressForm.value);
  }

  handleErr(err: any): void {}

  handleSuccess(res: any): void {
    return;
  }
}
