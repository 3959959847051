import { IsIn, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { Model, Types } from 'mongoose';
import { VendorAuthorization } from '../company/vendor-authorization.interfaces';

export type TVendorStatus = 'active' | 'inactive' | 'pending';

export interface IVendor {
  _id: string;
  vendorType: 'standard';
  providerName: string;
  appName: string;
  status: TVendorStatus;
  desc: string;
}

export interface IVendorCustom {
  _id: string;
  vendorType: 'custom';
  providerName: IVendor['providerName'];
  appName: IVendor['appName'];
  status: null;
  keys: string[];
  streams: null;
  desc: null;
  authorizations: string[];
}

export interface IStandardVendorCompanyRef {
  vendorId: string;
  keys: string[];
  authorizations: string[];
}

export class VendorAuthRef {
  @IsString()
  @IsNotEmpty()
  _id: IVendor['_id'];
  @IsString()
  @IsIn(['standard', 'custom'])
  vendorType: IVendor['vendorType'];
  @IsString()
  @IsNotEmpty()
  providerName: IVendor['providerName'];
  @IsString()
  @IsNotEmpty()
  appName: IVendor['appName'];
}

export interface IVendorInitializer {
  providerName: IVendor['providerName'];
  appName: IVendor['appName'];
  desc?: IVendor['desc'];
  status?: TVendorStatus;
}

export interface IVendorCustomInitializer {
  providerName: IVendorCustom['providerName'];
  appName?: IVendorCustom['appName'];
}

export interface IVendorModelOverrides {
  _id: Types.ObjectId;
  streams: Types.Array<Types.ObjectId>;
}

export interface IVendorModel
  extends Model<IVendor, {}, IVendorModelOverrides> {}

// CREATE

export class CreateVendorDto {
  @IsString()
  @IsNotEmpty()
  providerName: IVendor['providerName'];
  @IsString()
  @IsNotEmpty()
  appName: IVendor['appName'];
  @IsString()
  @IsOptional()
  desc?: IVendor['desc'];
  @IsIn(['active', 'inactive', 'pending'])
  @IsOptional()
  status?: TVendorStatus;
}

export class VendorCustomCreateDto {
  @IsString()
  @IsNotEmpty()
  companyId: string;
  @IsString()
  @IsNotEmpty()
  providerName: IVendorCustom['providerName'];
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  appName?: IVendorCustom['appName'];
}

// READ

export interface IGetVendorsResponse {
  count: number;
  vendors: IVendor[];
}

export class GetVendorsByIdDto {
  @IsString({ each: true })
  @IsNotEmpty({ each: true })
  vendorIds: string[];
}

export class GetCompanyVendorsDto {
  @IsString()
  @IsNotEmpty()
  companyId: string;
}

// UPDATE

export class VendorUpdateDto {
  @IsString()
  @IsNotEmpty()
  vendorId: string;
  @IsString()
  @IsOptional()
  providerName?: IVendor['providerName'];
  @IsString()
  @IsOptional()
  appName?: IVendor['appName'];
  @IsIn(['active', 'inactive', 'pending'])
  @IsOptional()
  status?: IVendor['status'];
}

export interface IVendorUpdateEvent {
  field: Exclude<keyof VendorUpdateDto, 'vendorId'>;
  value: VendorUpdateDto[Exclude<keyof VendorUpdateDto, 'vendorId'>];
}

export class AssignVendorToCompanyDto {
  @IsString()
  @IsNotEmpty()
  vendorType: IVendor['vendorType'] | IVendorCustom['vendorType'] | 'none';
  @IsString()
  @IsNotEmpty()
  companyId: string;
  @IsString()
  @IsNotEmpty()
  moduleId: string;
  @IsString()
  @IsNotEmpty()
  submoduleId: string;
  @IsString()
  @IsNotEmpty()
  questionKey: string;
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  vendorId?: string | null;
}

export interface IVendorDiscoveryDataResponse {
  vendorType: 'standard' | 'custom' | 'none';
  vendorId: string | null;
}

export interface ICompanyVendors {
  vendors: IVendor[];
  customVendors: IVendorCustom[];
  vendorAuthorizationSnapshots: VendorAuthorization[];
}
