import { animate, style, transition, trigger } from '@angular/animations';

export const groupTitleAnimation = trigger('zoom', [
  transition(':enter', [
    style({ transform: 'scale(4)', top: '200px', left: '150px', opacity: 0 }),
    animate('.25s ease-in-out', style({ opacity: 1 })),
    animate(
      '.5s 1s ease-in-out',
      style({ transform: 'scale(1)', top: '0px', left: '0px' }),
    ),
  ]),
]);
