<div class="flex flex-1 flex-row justify-center">
  <form
    [formGroup]="addressForm"
    (ngSubmit)="submit()"
    class="flex max-w-md flex-1 flex-col"
  >
    <!-- LINE 1 -->
    <div class="flex flex-col">
      <div dominionStandardFormLabel [required]="true" class="flex flex-row">
        Street Address
      </div>
      <div #streetHost dominionPopoverHost class="flex flex-row">
        <input
          dominionPopoverTarget
          dominionAddressTextInput
          type="text"
          formControlName="street"
          name="street"
          (input)="checkStreetErr()"
          (blur)="checkStreetErr()"
        />
        <div dominionPopover>
          <span dominionStandardFormError>Please enter a street address</span>
        </div>
      </div>
    </div>

    <!-- LINE 2 -->
    <div class="flex flex-col">
      <div dominionStandardFormLabel class="flex flex-row">PO Box or Suite</div>
      <div class="flex flex-row">
        <input
          dominionAddressTextInput
          type="text"
          formControlName="line2"
          name="line2"
        />
      </div>
    </div>

    <!-- CITY, STATE, ZIP -->
    <div class="flex flex-auto flex-row space-x-1">
      <!-- CITY -->
      <div class="flex flex-auto flex-col">
        <div dominionStandardFormLabel [required]="true" class="flex flex-row">
          City
        </div>

        <div #cityHost dominionPopoverHost class="flex flex-row">
          <input
            dominionPopoverTarget
            dominionAddressTextInput
            type="text"
            formControlName="city"
            name="city"
            (input)="checkCityErr()"
            (blur)="checkCityErr()"
          />
          <div dominionPopover>
            <span dominionStandardFormError>Please enter a city</span>
          </div>
        </div>
      </div>

      <!-- STATE -->
      <div class="flex flex-col">
        <div dominionStandardFormLabel [required]="true" class="flex flex-row">
          State
        </div>
        <div #stateHost dominionPopoverHost class="flex min-w-[100px] flex-row">
          <dominion-dropdown-search
            dominionPopoverTarget
            viewType="form"
            class="flex flex-1"
            toggleText="State"
            togglePaddingY="py-[6px]"
            [options]="states"
            [selectedOption]=""
            [dropdownCaret]="true"
            dropdownCaretHeight="h-2"
            dropdownCaretWidth="w-2"
            [hoverBorder]="true"
            [selectedOption]="selectedState"
            (emitClosed)="stateClosed()"
            (emitSelectedValue)="stateSelected($event)"
          ></dominion-dropdown-search>
          <div dominionPopover class="">
            <span dominionStandardFormError>Please select a state</span>
          </div>
        </div>
      </div>

      <!-- ZIP -->
      <div class="flex flex-shrink flex-col">
        <div dominionStandardFormLabel [required]="true" class="flex flex-row">
          Zip
        </div>

        <div #zipHost class="flex flex-row" dominionPopoverHost>
          <input
            dominionPopoverTarget
            dominionAddressTextInput
            type="text"
            formControlName="zip"
            name="zip"
            size="5"
            (input)="checkZipErr()"
            (blur)="checkZipErr()"
          />
          <div dominionPopover>
            <span dominionStandardFormError
              >Please enter a valid 5-digit zip code</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- NEXT BUTTON -->
    <div class="mt-4 flex flex-row justify-end">
      <button
        type="submit"
        [ngClass]="{ disabled: addressForm.invalid }"
        [disabled]="addressForm.invalid"
        dominionDiscoveryNextBtn
      >
        Next
      </button>
    </div>
  </form>
</div>
