<div class="flex flex-1 flex-row justify-center">
  <div class="flex w-full max-w-[500px] flex-col">
    <div
      dominionPopoverHost
      [formGroup]="form"
      class="focus-within:ring-dms-light-green flex flex-1 flex-row rounded border border-solid border-gray-300 p-2 ring-offset-1 focus-within:ring"
      data-testid="editable-text-active"
    >
      <!-- TEXT INPUT -->
      <input
        dominionPopoverTarget
        #inputEl
        type="text"
        formControlName="text"
        class="flex flex-1 flex-row text-center outline-none"
        (input)="resetErrors()"
        (blur)="checkErrors()"
        data-testid="editable-text-input"
      />
      <div
        dominionPopover
        class="text-sm font-normal text-red-600"
        data-testid="editable-text-popover"
      >
        <ng-container *ngIf="form.invalid">
          {{ errorMessage }}
        </ng-container>
        <ng-container *ngIf="form.invalid && serverErrMsg">
          <br />
        </ng-container>
        <ng-container *ngIf="serverErrMsg">
          {{ serverErrMsg }}
        </ng-container>
      </div>
    </div>
    <div class="mt-8 flex flex-row justify-end">
      <button
        dominionDiscoveryNextBtn
        type="submit"
        (click)="save()"
        [ngClass]="{ disabled: form.get('text')?.invalid }"
        class=""
      >
        Next
      </button>
    </div>
  </div>
</div>
