import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { Observer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'dominion-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  isErr = false;
  errMessage = '';
  loginForm: FormGroup;
  showPassword = false;

  loginObserver: Partial<Observer<void>> = {
    next: () => {
      this.loginSuccess();
    },
    error: (err: HttpErrorResponse) => {
      this.handleErr(err);
    },
  };

  @ViewChild('password') password: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.authService.user.subscribe({
      next: (user) => {
        if (user) {
          if (user.userType === 'internal') {
            this.router.navigateByUrl('core/admin');
          } else {
            this.router.navigateByUrl('core');
          }
        }
      },
    });
  }

  togglePassword() {
    if (this.showPassword) {
      this.showPassword = false;
      this.password.nativeElement.setAttribute('type', 'password');
    } else {
      this.showPassword = true;
      this.password.nativeElement.setAttribute('type', 'text');
    }
  }

  handleErr(err: HttpErrorResponse) {
    this.spinner.hide('login');
    this.isErr = true;
    this.errMessage = err.error.message;
  }

  clearErr() {
    this.isErr = false;
    this.errMessage = '';
  }

  login() {
    if (this.loginForm.valid) {
      this.clearErr();
      this.spinner.show('login', {
        fullScreen: false,
        color: '#ffffff',
        size: 'small',
        type: 'ball-clip-rotate-pulse',
        bdColor: 'rgba(6, 38, 45, .7)',
      });
      const email = this.loginForm.get('email')?.value;
      const password = this.loginForm.get('password')?.value;
      this.authService.login({ email, password }).subscribe(this.loginObserver);
    }
    return;
  }

  loginSuccess() {
    this.spinner.hide('login');
    this.authService.user.subscribe({
      next: (user) => {
        if (user?.userType === 'internal') {
          this.router.navigateByUrl('core/admin');
        } else {
          this.router.navigateByUrl('core');
        }
      },
    });
  }
}
