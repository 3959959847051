import { Types } from 'mongoose';
import { UserType } from './user/user.interfaces';
import { IsString } from 'class-validator';

interface IRequestUserPayload {
  email: string;
  _id: string;
  firstName: string;
  userType: UserType;
  isDeactivated: boolean;
}

interface JwtAccessToken {
  access_token: string;
}

interface INestRequestPayload {
  user: IRequestUserPayload;
}

class ChangePasswordDto {
  @IsString()
  oldPassword: string;
  @IsString()
  newPassword: string;
}

class ForgotPasswordDto {
  @IsString()
  email: string;
}

interface IResetPasswordEmailDto {
  recipientId: string;
  recipientEmail: string;
  recipientFirstName: string;
  resetToken: string;
}

class ResetPasswordDto {
  @IsString()
  userId: string;
  @IsString()
  resetToken: string;
  @IsString()
  password: string;
}

export {
  IRequestUserPayload,
  JwtAccessToken,
  INestRequestPayload,
  ChangePasswordDto,
  ForgotPasswordDto,
  IResetPasswordEmailDto,
  ResetPasswordDto,
};
