<div class="flex flex-row justify-center">
  <div class="flex max-w-[500px] flex-1 flex-col">
    <div class="flex flex-row justify-center">
      <div class="flex max-w-[500px] flex-1 flex-row space-x-[6px]">
        <div class="flex flex-auto basis-2/3">
          <dominion-dropdown-search
            class="flex flex-auto"
            [options]="monthOptions"
            [dropdownCaret]="true"
            [capitalizeOptionLabels]="true"
            [ring]="true"
            [selectedOption]="selectedMonthOption"
            (emitSelectedValue)="setMonth($event)"
            [toggleTextSize]="'text-lg'"
            [optionsTextSize]="'text-md'"
          ></dominion-dropdown-search>
        </div>
        <div class="flex flex-auto basis-1/3">
          <dominion-dropdown-search
            class="flex flex-auto"
            [disabled]="isDayInputDisabled()"
            [options]="selectedMonthOption | getDayOptions"
            [dropdownCaret]="true"
            [selectedOption]="selectedDayOption"
            [toggleTextSize]="'text-lg'"
            [optionsTextSize]="'text-md'"
            (emitSelectedValue)="setDay($event)"
            [ring]="true"
          ></dominion-dropdown-search>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <div class="mt-4 flex max-w-[500px] flex-auto flex-row justify-end">
        <button
          dominionDiscoveryNextBtn
          [ngClass]="{ disabled: fiscalYearEndNextBtnDisabled() }"
          (click)="saveFiscalYearEndDate()"
          [disabled]="fiscalYearEndNextBtnDisabled()"
          type="button"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</div>
