import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { ResponseOptions } from '@dominion/interfaces';
import { DiscoveryBtnDirective } from '../../shared/discovery-btn/discovery-btn.directive';

@Component({
  selector: 'dominion-input-select',
  standalone: true,
  imports: [CommonModule, DiscoveryBtnDirective],
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css'],
})
export class InputSelectComponent implements ResponseInput {
  @Input({ required: true }) data: any;
  @Input({ required: true }) options: ResponseOptions[];

  @Output() response: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  initializeSelection() {}

  handleErr(err: any): void {
    throw new Error(err);
  }

  handleSuccess(res: any): void {
    return;
  }

  selectResponse(value: any): void {
    this.response.emit(value);
  }
}
