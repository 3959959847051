import {
  CompanyAuthRole,
  CompanyDept,
  CompanyRole,
  DominionAuthRole,
  DominionDept,
  IUserCreateDto,
  IUserUpdateDto,
  UserType,
} from '@dominion/interfaces';
import {
  IsEmail,
  IsString,
  IsIn,
  IsOptional,
  MinLength,
  IsNotEmpty,
} from 'class-validator';

export class UserCreateDto implements IUserCreateDto {
  @IsString()
  firstName: string;
  @IsString()
  lastName: string;
  @IsEmail()
  email: string;
  @IsIn(['internal', 'external'])
  userType: UserType;
  @IsOptional()
  role: CompanyRole | undefined;
  @IsOptional()
  department: DominionDept | CompanyDept;
  @MinLength(8)
  @IsString()
  password: string;
  @IsString()
  jobTitle: string;
  [key: string]: unknown;
}

const DominionDeptList: DominionDept[] = [
  'deployment',
  'implementation',
  'other',
  'pm',
  'sales',
  'support',
];

const CompanyDeptList: CompanyDept[] = [
  'accounting',
  'parts',
  'sales',
  'service',
  'other',
];

const CompanyRoleList: CompanyRole[] = [
  'accounting_clerk',
  'accounting_manager',
  'accounts_payable_clerk',
  'accounts_receivable_clerk',
  'billing_clerk',
  'controller',
  'cfo',
  'fi_manager',
  'general_manager',
  'general_sales_manager',
  'hr_manager',
  'hr_payroll_assistant',
  'inventory_clerk',
  'payroll_clerk',
  'parts_counter',
  'parts_manager',
  'sales_cashier',
  'sales_manager',
  'salesperson',
  'service_advisor',
  'service_manager',
  'technician',
  'warranty_clerk',
];

export class UserUpdateDto implements IUserUpdateDto {
  @IsOptional()
  @IsString()
  firstName?: string;
  @IsOptional()
  @IsString()
  lastName?: string;
  @IsOptional()
  @IsIn(CompanyRoleList)
  role?: CompanyRole;
  @IsOptional()
  @IsString()
  jobTitle?: string;
  @IsOptional()
  @IsIn([...DominionDeptList, ...CompanyDeptList])
  department?: DominionDept | CompanyDept;
  [key: string]: unknown;
}

export class UserDominionAuthDto {
  @IsNotEmpty()
  @IsString()
  userId: string;
  @IsIn([
    'super',
    'admin',
    'projectmanager',
    'deploymentmanager',
    'deploymenttechnician',
    'viewer',
  ] as DominionAuthRole[])
  role: DominionAuthRole;
}

export class UserExternalAuthDto {
  @IsNotEmpty()
  @IsString()
  userEmail: string;
  @IsNotEmpty()
  @IsString()
  companyId: string;
  @IsIn(['super', 'admin', 'manager'] as CompanyAuthRole[])
  role: CompanyAuthRole;
}
