<ul role="list" class="mx-auto flex w-full max-w-lg flex-col gap-1">
  @for (option of options; track option.value) {
    <li>
      <button
        dominionDiscoveryBtn
        (click)="selectResponse(option.value)"
        [attr.aria-selected]="data === option.value"
        class="w-full"
      >
        {{ option.label }}
      </button>
    </li>
  }
</ul>
