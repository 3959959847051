import {
  Component,
  HostBinding,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyService } from '../company/company.service';
import { ActivatedRoute } from '@angular/router';
import { DiscoverySideNavComponent } from '../discovery-side-nav/discovery-side-nav.component';
import { InformationButtonComponent } from '../shared/information-button/information-button.component';
import {
  FileGetDto,
  ICompanyReadFull,
  IFileUploadReference,
} from '@dominion/interfaces';
import { IconDownloadComponent } from '../icons/icon-download.component';
import { saveAs } from 'file-saver';
import { TableComponent } from '../shared/table/table.component';

@Pipe({
  name: 'fileQuestionKey',
  standalone: true,
})
export class FileQuestionKeyPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'chartOfAccounts':
        return 'Chart of Accounts';
      case 'samplePositivePay':
        return 'Sample Positive Pay';
      case 'samplePayroll':
        return 'Sample Payroll';
      case 'replica401k':
        return 'Replica 401k';
      default:
        return value;
    }
  }
}

@Component({
  selector: 'dominion-company-documents',
  standalone: true,
  imports: [
    CommonModule,
    DiscoverySideNavComponent,
    InformationButtonComponent,
    IconDownloadComponent,
    FileQuestionKeyPipe,
    TableComponent,
  ],
  templateUrl: './company-documents.component.html',
  styleUrls: ['./company-documents.component.css'],
})
export class CompanyDocumentsComponent implements OnInit {
  companyId: string;
  company: ICompanyReadFull;

  @HostBinding('class') class =
    'container mx-auto flex-auto overflow-hidden flex flex-row';

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
  ) {}

  getCompany() {
    this.companyService.getCompany(this.companyId).subscribe({
      next: (res) => {
        this.company = res;
      },
    });
  }

  downloadFile(fileRef: IFileUploadReference) {
    const dto: FileGetDto = {
      companyId: fileRef.companyId,
      fileId: fileRef._id,
      moduleId: fileRef.moduleId,
    };
    this.companyService.getFile(dto).subscribe({
      next: (res) => {
        const asBlob = new Blob([res]);
        saveAs(asBlob, fileRef.name);
      },
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['companyid'];
      this.getCompany();
    });
  }
}
