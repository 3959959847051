import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import {
  IRequestUserPayload,
  IUserReadPrivateBrief,
  UserUpdateDto,
} from '@dominion/interfaces';
import { Observer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { EditableTextComponent } from '../shared/editable-text/editable-text.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IconRightCaretComponent } from '../icons/right-caret.component';
import { RouterLink } from '@angular/router';

export type IUserUpdateEvent = {
  field: keyof IUserReadPrivateBrief;
  value: unknown;
};

@Component({
  selector: 'dominion-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.css'],
  standalone: true,
  imports: [SharedModule, CommonModule, IconRightCaretComponent, RouterLink],
})
export class ManageAccountComponent implements OnInit {
  public tokenUser: IRequestUserPayload | undefined;
  public user: IUserReadPrivateBrief | undefined;
  public isComponentLoading: boolean = true;

  private userObserver: Observer<IUserReadPrivateBrief> = {
    next: (user: IUserReadPrivateBrief) => {
      this.user = user;
      this.isComponentLoading = false;
    },
    error: (error: HttpErrorResponse) => {
      console.error(error);
    },
    complete: () => {
      return;
    },
  };

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
  ) {}

  private getFullUser() {
    if (this.tokenUser) {
      this.userService
        .getFullUser(this.tokenUser._id as string)
        .subscribe(this.userObserver);
    }
  }

  onUserUpdate(event: IUserUpdateEvent, editableComp: EditableTextComponent) {
    const dto: UserUpdateDto = {
      [event.field]: event.value,
    };
    this.updateUser(dto, editableComp);
  }

  private updateUser(dto: UserUpdateDto, editableComp: EditableTextComponent) {
    // call service
    if (!this.user) {
      throw new Error('User is not defined');
    }
    this.userService.updateUser(this.user._id, dto).subscribe({
      next: () => {
        if (!this.user) {
          throw new Error('User is not defined');
        }
        this.user = Object.assign(this.user, dto);
        editableComp.saveSucceeded();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        editableComp.saveFailed(err.error.message);
      },
    });
  }

  sendForgotPasswordRequest() {
    this.authService.sendForgotPasswordRequest(this.user!.email).subscribe({
      next: () => {
        return;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
      },
    });
  }

  getTokenUser() {
    return this.authService.user.subscribe({
      next: (user) => {
        this.tokenUser = user;
      },
    });
  }

  ngOnInit(): void {
    this.getTokenUser();
    this.getFullUser();
    // get user from db
  }
}
