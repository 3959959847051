import {
  Component,
  HostBinding,
  OnChanges,
  OnInit,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../company/company.service';
import {
  AuthorizationStrategy,
  AuthorizeVendorsDto,
  IRequestUserPayload,
  IUserAuthorizations,
  IVendor,
  IVendorCustom,
  VendorAuthRef,
  VendorAuthorization,
} from '@dominion/interfaces';
import { DiscoverySideNavComponent } from '../../discovery-side-nav/discovery-side-nav.component';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { AuthenticationService } from '../../auth/authentication.service';
import { AuthorizationService } from '../../auth/authorization.service';
import { CompanySuperStrategy } from '@dominion/authorization';
import { ModalComponent } from '../../shared/modal/modal.component';
import { ExpandableHostDirective } from '../../shared/directives/expandable-host.directive';
import { ExpandableDirective } from '../../shared/directives/expandable.directive';
import { ExpandableToggleDirective } from '../../shared/directives/expandable-toggle.directive';
import { DropdownCaretComponent } from '../../shared/dropdown-caret/dropdown-caret.component';
import { IconCheckCircleComponent } from '../../icons/icon-check-circle.component';
import { IconXCircleComponent } from '../../icons/icon-x-circle.component';
import { TableComponent } from '../../shared/table/table.component';

@Pipe({
  name: 'isVendorAuthorized',
  standalone: true,
})
export class IsVendorAuthorizedPipe implements PipeTransform {
  transform(
    vendor: IVendor,
    vendorAuth: VendorAuthorization | undefined,
  ): boolean {
    if (!vendorAuth) {
      return false;
    }
    const ref = vendorAuth.vendors.find((v) => v._id === vendor._id);
    return ref ? true : false;
  }
}

@Component({
  selector: 'dominion-company-vendors',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    DiscoverySideNavComponent,
    InformationButtonComponent,
    ExpandableHostDirective,
    ExpandableDirective,
    ExpandableToggleDirective,
    IconCheckCircleComponent,
    DropdownCaretComponent,
    IconXCircleComponent,
    DropdownCaretComponent,
    IconGoToComponent,
    IsVendorAuthorizedPipe,
    TableComponent,
  ],
  templateUrl: './company-vendors.component.html',
  styleUrls: ['./company-vendors.component.css'],
})
export class CompanyVendorsComponent implements OnInit, OnChanges {
  companyId: string;
  vendors: IVendor[] = [];
  customVendors: IVendorCustom[] = [];
  vendorAuthorizations: VendorAuthorization[] = [];

  isLoadingVendors = false;
  user: IRequestUserPayload | undefined;
  authorizations: IUserAuthorizations | undefined;
  canAuthorizeVendors = false;

  @HostBinding('class') class =
    'container mx-auto flex-auto overflow-hidden flex flex-row';

  @ViewChild('authorizationModal') authorizeModal: ModalComponent;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private authentication: AuthenticationService,
    private authorization: AuthorizationService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['companyId']) {
      this.getCompanyVendors();
    }
  }

  getCompanyVendors() {
    if (this.companyId) {
      this.companyService.getCompanyVendors(this.companyId).subscribe({
        next: (response) => {
          this.vendors = response.vendors.sort((a, b) => {
            const lowerA = a.providerName.toLocaleLowerCase();
            const lowerB = b.providerName.toLocaleLowerCase();
            if (lowerA < lowerB) {
              return -1;
            }
            if (lowerA > lowerB) {
              return 1;
            }
            return 0;
          });
          this.customVendors = response.customVendors.sort((a, b) => {
            const lowerA = a.providerName.toLocaleLowerCase();
            const lowerB = b.providerName.toLocaleLowerCase();
            if (lowerA < lowerB) {
              return -1;
            }
            if (lowerA > lowerB) {
              return 1;
            }
            return 0;
          });
          this.vendorAuthorizations = response.vendorAuthorizationSnapshots
            ? response.vendorAuthorizationSnapshots
            : [];
        },
      });
    }
  }

  initializeUserPermissions() {
    if (this.authorizations) {
      const strategy: AuthorizationStrategy = [
        new CompanySuperStrategy(this.companyId),
      ];
      this.canAuthorizeVendors = this.authorization.isAuthorized(strategy);
    }
  }

  authorizeVendors() {
    const vendorAuthRefs: VendorAuthRef[] = [];
    this.vendors.forEach((vendor) => {
      vendorAuthRefs.push({
        _id: vendor._id,
        vendorType: 'standard',
        providerName: vendor.providerName,
        appName: vendor.appName,
      });
    });
    const dto: AuthorizeVendorsDto = {
      companyId: this.companyId,
      vendors: vendorAuthRefs,
    };
    this.companyService.authorizeVendors(dto).subscribe({
      next: (vendorAuth) => {
        this.vendorAuthorizations.unshift(vendorAuth);
        this.authorizeModal.close();
      },
    });

    // generate list of vendor auth refs
    // create the dto
    // send
    // receive VendorAuth on success
    // push vendor auth into company auth array
    // close modal
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['companyid'];
      this.getCompanyVendors();
      this.authorization.authorizations.subscribe((authorizations) => {
        this.authorizations = authorizations;
        this.initializeUserPermissions();
      });
      this.authentication.user.subscribe((user) => {
        this.user = user;
      });
    });
  }
}
