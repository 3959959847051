import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IVendor,
  IVendorQuestion,
  IVendorReference,
  ModuleType,
  VendorQuestion,
  VendorQuestionUpdateVendorsDto,
} from '@dominion/interfaces';
import { SharedModule } from '../shared/shared.module';
import { OptionToggleComponent } from '../shared/option-toggle/option-toggle.component';
import { DropdownOption } from '../shared/dropdown-search/dropdown-search.component';
import { VendorService } from '../vendors/vendor.service';
import { ModalComponent } from '../shared/modal/modal.component';
import { StandardDeleteButtonsComponent } from '../shared/standard-delete-buttons/standard-delete-buttons.component';

const modules: ModuleType[] = [
  'parts',
  'service',
  'accounting',
  'fi',
  'sales',
  'payroll',
];

export interface ISelectVendorEvent {
  vendorId: string;
  moduleType: ModuleType;
  questionKey: string;
}

type DeletionStage = {
  vendor: {
    question: IVendorQuestion;
    desc: string;
    obj: IVendorReference;
  } | null;
};

@Component({
  selector: 'dominion-vendor-questions',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    OptionToggleComponent,
    StandardDeleteButtonsComponent,
  ],
  templateUrl: './vendor-questions.component.html',
  styleUrls: ['./vendor-questions.component.css'],
})
export class VendorQuestionsComponent implements OnChanges {
  @Input() vendors: IVendor[] = [];
  @Input() vendorQuestions: IVendorQuestion[] = [];
  @Input() selectedModuleGroup: DropdownOption = { label: 'All', value: 'all' };

  @Output() emitAddVendor: EventEmitter<ISelectVendorEvent> =
    new EventEmitter<ISelectVendorEvent>();

  vendorOptions: DropdownOption[] = [];
  isAddingVendor: boolean = false;

  deletion: DeletionStage = {
    vendor: null,
  };

  moduleSortedVendorQuestions: {
    moduleType: ModuleType;
    questions: IVendorQuestion[];
  }[] = [];

  @ViewChild('deleteModal') deleteModal: ModalComponent;

  constructor(private vendorService: VendorService) {}

  addVendor(selected: DropdownOption, question: IVendorQuestion) {
    this.isAddingVendor = true;
    const dto: VendorQuestionUpdateVendorsDto = {
      vendorId: selected.value as string,
      vendorQuestionId: question._id,
    };
    this.vendorService.addVendorToQuestion(dto).subscribe({
      next: (res) => {
        // find the vendor object from vendor list
        // push it into the vendors for this question
        const vendorObj = this.vendors.find((v) => {
          return v._id === dto.vendorId;
        });
        if (vendorObj) {
          const copy = Object.assign({ vendorId: dto.vendorId }, vendorObj);
          question.vendors.push(copy);
          question.vendors.sort((a, b) => {
            const aLower = a.providerName.toLowerCase();
            const bLower = b.providerName.toLowerCase();
            if (aLower < bLower) {
              return -1;
            } else {
              return 1;
            }
          });
        }
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vendorQuestions']) {
      this.buildModuleSortedQuestions();
    }
    if (changes['vendors']) {
      this.buildVendorOptions();
    }
  }

  buildVendorOptions() {
    this.vendorOptions = this.vendors.map((v) => ({
      label: v.providerName + ': ' + v.appName,
      value: v._id,
    }));
  }

  buildModuleSortedQuestions() {
    this.moduleSortedVendorQuestions = [];
    for (const module of modules) {
      const questions = this.vendorQuestions.filter(
        (q) => q.moduleType === module,
      );
      for (const question of questions) {
        question.vendors.sort((a, b) => {
          const aLower = a.providerName.toLowerCase();
          const bLower = b.providerName.toLowerCase();
          if (aLower < bLower) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      this.moduleSortedVendorQuestions.push({
        moduleType: module,
        questions,
      });
    }
  }

  stageVendorDeletion(question: IVendorQuestion, vendor: IVendorReference) {
    this.deletion.vendor = {
      desc: `${vendor.providerName}: ${vendor.appName}`,
      obj: vendor,
      question,
    };
    this.deleteModal.open();
  }

  deleteVendor() {
    if (!this.deletion.vendor) {
      throw new Error('No vendor deletion staged');
    }
    const dto: VendorQuestionUpdateVendorsDto = {
      vendorId: this.deletion.vendor.obj.vendorId,
      vendorQuestionId: this.deletion.vendor.question._id,
    };
    this.vendorService.deleteVendorFromQuestion(dto).subscribe({
      next: (res) => {
        // need to remove the vendor ref from the question now
        this.deletion.vendor?.question.vendors.splice(
          this.deletion.vendor.question.vendors.findIndex((v) => {
            return v.vendorId === this.deletion.vendor?.obj.vendorId;
          }),
          1,
        );
        this.deletion.vendor = null;
        this.deleteModal.close();
      },
    });
  }

  cancelDeletion() {
    this.deletion = {
      vendor: null,
    };
    this.deleteModal.close();
  }
}
