<div #container class="flex flex-1 flex-col">
  @for (question of requiredQuestions; track question.label; let i = $index) {
    @if (i <= activeIndex) {
      <div class="flex flex-1 flex-row justify-center pt-16">
        <div
          [ngClass]="{
            'shadow-2xl': i === activeIndex,
            'shadow-md': i !== activeIndex,
          }"
          class="flex max-w-[700px] flex-1 flex-col rounded-md border px-4 py-16"
        >
          <div class="mb-10 flex flex-1 flex-row justify-center">
            <dominion-prompt
              class="max-w-[500px] justify-center text-center text-2xl"
              [prompt]="question.prompt"
            ></dominion-prompt>
          </div>
          @if (question.subprompt.length > 0) {
            <div class="mb-10 flex flex-row justify-center text-gray-600">
              <div
                class="bg-dms-light-green/[.075] flex w-[500px] flex-row rounded-sm p-4"
              >
                <dominion-prompt
                  class="max-w-[500px] text-[15px]"
                  [prompt]="question.subprompt"
                  [subprompt]="true"
                ></dominion-prompt>
              </div>
            </div>
          }
          @switch (question.componentType) {
            @case ('text') {
              <dominion-input-text
                #input
                [data]="data[question.questionKey].value"
                [validation]="question.validation"
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
              ></dominion-input-text>
            }
            @case ('textarea') {
              <dominion-input-textarea
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
              ></dominion-input-textarea>
            }
            @case ('select') {
              <dominion-input-select
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
                [options]="question.options"
              ></dominion-input-select>
            }
            @case ('select-list') {
              <dominion-input-select-list
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
                [options]="question.options"
              ></dominion-input-select-list>
            }
            @case ('select-multi') {
              <dominion-input-select
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
                [options]="question.options"
              ></dominion-input-select>
            }
            @case ('dropdown') {
              <dominion-input-dropdown
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
                [options]="question.options"
              ></dominion-input-dropdown>
            }
            @case ('dropdown-multi') {
              <dominion-input-dropdown
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
                [options]="question.options"
              ></dominion-input-dropdown>
            }
            @case ('address') {
              <dominion-input-address
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
              ></dominion-input-address>
            }
            @case ('date') {
              <dominion-input-date
                #input
                (advanceEvent)="advance(question.questionKey, i)"
                (response)="
                  recordPresavedResponse($event, question.questionKey, i)
                "
                [data]="data[question.questionKey].value"
              ></dominion-input-date>
            }
            @case ('file') {
              <dominion-input-file-container
                #input
                [data]="data[question.questionKey].value"
                [allowSkip]="question.allowSkip"
                (advanceEvent)="advance(question.questionKey, i)"
                (response)="
                  recordPresavedResponse($event, question.questionKey, i)
                "
                [companyId]="companyid"
                [moduleId]="moduleid"
                [submoduleId]="submoduleid"
                [questionKey]="question.questionKey"
              ></dominion-input-file-container>
            }
            @case ('file-multi') {
              <dominion-input-file
                #input
                [data]="data[question.questionKey].value"
                [companyId]="companyid"
                [moduleId]="moduleid"
                [submoduleId]="submoduleid"
                [questionKey]="question.questionKey"
              ></dominion-input-file>
            }
            @case ('month-day') {
              <dominion-input-month-day
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
              ></dominion-input-month-day>
            }
            @case ('contact') {
              <dominion-input-contact
                #input
                (response)="
                  recordResponse($event, question.questionKey, input, i)
                "
                [data]="data[question.questionKey].value"
                [sections]="question.sections"
              ></dominion-input-contact>
            }
          }
        </div>
      </div>
    }
  }
</div>
