import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { ResponseOptions } from '@dominion/interfaces';
import { DiscoveryBtnDirective } from '../../shared/discovery-btn/discovery-btn.directive';

// Notes:
// input-select could be simpler, >4 items automatic dropdown
// input-select-long would never be a dropdown

@Component({
  selector: 'dominion-input-select-list',
  standalone: true,
  imports: [CommonModule, DiscoveryBtnDirective],
  templateUrl: './input-select-list.component.html',
})
export class InputSelectListComponent implements ResponseInput {
  @Input({ required: true }) data: any;
  @Input({ required: true }) options: ResponseOptions[];

  @Output() response: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  initializeSelection() {}

  handleErr(err: any): void {
    throw new Error(err);
  }

  handleSuccess(res: any): void {
    return;
  }

  selectResponse(value: any): void {
    this.response.emit(value);
  }
}
