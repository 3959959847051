<div class="container flex justify-between">
  <a
    routerLink="/"
    class="text-dms-teal font-lato flex items-center text-2xl font-bold uppercase"
  >
    <img src="../../assets/vox-logo.svg" class="mr-2 h-8 w-8" />
    <span class="bold uppercase">VOX</span>
  </a>
  <div class="flex flex-row-reverse">
    <div
      dominionDropdownHost
      *ngIf="user?.userType === 'external'"
      class="hover:bg-dms-light-grey flex w-[220px] flex-grow cursor-pointer flex-row justify-between bg-sky-700 px-3 text-white hover:text-black"
    >
      <div
        dominionDropdownToggle
        class="flex flex-grow flex-row items-center overflow-hidden"
      >
        <div class="flex-0 relative mr-2 h-5 w-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="absolute"
            viewBox="0 0 24 24"
          >
            <defs>
              <style>
                .i-companies {
                  fill: none;
                  stroke: currentColor;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.5px;
                }
              </style>
            </defs>
            <title>Company</title>
            <line
              class="i-companies"
              x1="20.25"
              y1="3.75"
              x2="20.25"
              y2="23.25"
            />
            <line
              class="i-companies"
              x1="3.75"
              y1="3.75"
              x2="20.25"
              y2="3.75"
            />
            <line
              class="i-companies"
              x1="3.75"
              y1="23.25"
              x2="3.75"
              y2="3.75"
            />
            <line
              class="i-companies"
              x1="20.25"
              y1="23.25"
              x2="3.75"
              y2="23.25"
            />
            <polygon
              class="i-companies"
              points="20.25 3.75 3.75 3.75 5.25 0.75 18.75 0.75 20.25 3.75"
            />
            <line
              class="i-companies"
              x1="0.75"
              y1="23.25"
              x2="23.25"
              y2="23.25"
            />
            <path
              class="i-companies"
              d="M14.25,21a2.25,2.25,0,0,0-4.5,0v2.25h4.5Z"
            />
            <path class="i-companies" d="M7.5,6.375a.375.375,0,0,1,.375.375" />
            <path
              class="i-companies"
              d="M7.125,6.75A.375.375,0,0,1,7.5,6.375"
            />
            <path class="i-companies" d="M7.5,7.125a.375.375,0,0,1-.375-.375" />
            <path class="i-companies" d="M7.875,6.75a.375.375,0,0,1-.375.375" />
            <path class="i-companies" d="M7.5,10.875a.375.375,0,0,1,.375.375" />
            <path
              class="i-companies"
              d="M7.125,11.25a.375.375,0,0,1,.375-.375"
            />
            <path
              class="i-companies"
              d="M7.5,11.625a.375.375,0,0,1-.375-.375"
            />
            <path
              class="i-companies"
              d="M7.875,11.25a.375.375,0,0,1-.375.375"
            />
            <path class="i-companies" d="M7.5,15.375a.375.375,0,0,1,.375.375" />
            <path
              class="i-companies"
              d="M7.125,15.75a.375.375,0,0,1,.375-.375"
            />
            <path
              class="i-companies"
              d="M7.5,16.125a.375.375,0,0,1-.375-.375"
            />
            <path
              class="i-companies"
              d="M7.875,15.75a.375.375,0,0,1-.375.375"
            />
            <path class="i-companies" d="M12,6.375a.375.375,0,0,1,.375.375" />
            <path
              class="i-companies"
              d="M11.625,6.75A.375.375,0,0,1,12,6.375"
            />
            <path class="i-companies" d="M12,7.125a.375.375,0,0,1-.375-.375" />
            <path
              class="i-companies"
              d="M12.375,6.75A.375.375,0,0,1,12,7.125"
            />
            <path class="i-companies" d="M12,10.875a.375.375,0,0,1,.375.375" />
            <path
              class="i-companies"
              d="M11.625,11.25A.375.375,0,0,1,12,10.875"
            />
            <path class="i-companies" d="M12,11.625a.375.375,0,0,1-.375-.375" />
            <path
              class="i-companies"
              d="M12.375,11.25a.375.375,0,0,1-.375.375"
            />
            <path class="i-companies" d="M12,15.375a.375.375,0,0,1,.375.375" />
            <path
              class="i-companies"
              d="M11.625,15.75A.375.375,0,0,1,12,15.375"
            />
            <path class="i-companies" d="M12,16.125a.375.375,0,0,1-.375-.375" />
            <path
              class="i-companies"
              d="M12.375,15.75a.375.375,0,0,1-.375.375"
            />
            <path class="i-companies" d="M16.5,6.375a.375.375,0,0,1,.375.375" />
            <path
              class="i-companies"
              d="M16.125,6.75a.375.375,0,0,1,.375-.375"
            />
            <path
              class="i-companies"
              d="M16.5,7.125a.375.375,0,0,1-.375-.375"
            />
            <path
              class="i-companies"
              d="M16.875,6.75a.375.375,0,0,1-.375.375"
            />
            <path
              class="i-companies"
              d="M16.5,10.875a.375.375,0,0,1,.375.375"
            />
            <path
              class="i-companies"
              d="M16.125,11.25a.375.375,0,0,1,.375-.375"
            />
            <path
              class="i-companies"
              d="M16.5,11.625a.375.375,0,0,1-.375-.375"
            />
            <path
              class="i-companies"
              d="M16.875,11.25a.375.375,0,0,1-.375.375"
            />
            <path
              class="i-companies"
              d="M16.5,15.375a.375.375,0,0,1,.375.375"
            />
            <path
              class="i-companies"
              d="M16.125,15.75a.375.375,0,0,1,.375-.375"
            />
            <path
              class="i-companies"
              d="M16.5,16.125a.375.375,0,0,1-.375-.375"
            />
            <path
              class="i-companies"
              d="M16.875,15.75a.375.375,0,0,1-.375.375"
            />
          </svg>
        </div>
        <div class="flex-grow truncate">{{ activeCompany?.name }}</div>
        <div class="relative ml-1 h-3 w-3 flex-shrink">
          <svg
            id="Regular"
            class="absolute"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <style>
                .i-down-arrow {
                  fill: currentColor;
                  stroke: currentColor;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1px;
                  fill-rule: evenodd;
                }
              </style>
            </defs>
            <title>Down Arrow</title>
            <path
              class="i-down-arrow"
              d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311"
            />
          </svg>
        </div>
      </div>
      <div
        dominionDropdownMenu
        class="text-dms-ultra-grey border-dms-light-grey z-50 hidden w-[220px] rounded-b-[4px] border bg-white shadow-md data-[show]:block"
        data-testid="nav-user-dropdown-menu"
      >
        <div
          class="hover:bg-dms-light-grey flex cursor-pointer flex-row px-2 py-2"
          (click)="goToCompany(company)"
          *ngFor="let company of companies"
        >
          {{ company.name }}
        </div>
      </div>
      <!--

    --></div>
    <div dominionDropdownHost class="flex w-[220px] flex-row justify-between">
      <div
        dominionDropdownToggle
        class="hover:bg-dms-light-grey flex flex-auto cursor-pointer items-center justify-between px-3"
        data-testid="nav-user-dropdown-toggle"
      >
        <div class="flex items-center">
          <div
            class="bg-dms-mid-green mr-2 flex h-6 w-6 items-center justify-center rounded-[4px] text-center text-lg uppercase text-white"
            data-testid="nav-user-symbol"
          >
            {{ user?.firstName?.charAt(0) }}
          </div>
          <span data-testid="nav-name">{{ user?.firstName }}</span>
        </div>
        <div class="relative h-3 w-3">
          <svg
            id="Regular"
            class="absolute"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <style>
                .i-down-arrow2 {
                  fill: black;
                  stroke: black;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1px;
                  fill-rule: evenodd;
                }
              </style>
            </defs>
            <title>Down Arrow</title>
            <path
              class="i-down-arrow2"
              d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311"
            />
          </svg>
        </div>
      </div>
      <div
        dominionDropdownMenu
        class="border-dms-light-grey z-50 hidden w-[220px] rounded-b-[4px] border bg-white shadow-md data-[show]:block"
        data-testid="nav-user-dropdown-menu"
      >
        <div
          (click)="goToManageAccount()"
          class="hover:bg-dms-light-grey flex cursor-pointer flex-row px-4 py-3"
        >
          Manage Account
        </div>
        <div
          (click)="logout()"
          class="hover:bg-dms-light-grey flex cursor-pointer flex-row px-4 py-3"
          data-testid="nav-logout-btn"
        >
          Log Off
        </div>
        <div
          class="flex flex-row justify-center border-t border-t-gray-100 p-1 text-xs text-gray-500"
        >
          Application: {{ appVersion }}
        </div>
      </div>
    </div>
    <div class="ml-5 h-2/3 w-2 self-center border-l border-gray-300"></div>

    <div
    (click)="handleSupportClick()"
      class="hover:bg-dms-light-grey flex cursor-pointer items-center justify-center px-2"
      data-testid="nav-support-btn"
    >
      <div  class="relative h-5 w-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-question-a,
              .i-question-b {
                fill: none;
                stroke: currentColor;
                stroke-width: 1.5px;
              }
              .i-question-a {
                stroke-linecap: round;
                stroke-linejoin: round;
              }
              .i-question-b {
                stroke-miterlimit: 10;
              }
            </style>
          </defs>
          <title>Support</title>
          <path
            class="i-question-a"
            d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
          />
          <path
            class="i-question-a"
            d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
          />
          <circle class="i-question-b" cx="12" cy="12" r="11.25" />
        </svg>
      </div>
    </div>
    <div
      class="hover:bg-dms-light-grey flex cursor-pointer items-center justify-center px-2"
    >
      <div class="relative h-5 w-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-notifications {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
              }
            </style>
          </defs>
          <title>Notifications</title>
          <path
            class="i-notifications"
            d="M10,21.75a2.087,2.087,0,0,0,4.005,0"
          />
          <line class="i-notifications" x1="12" y1="3" x2="12" y2="0.75" />
          <path
            class="i-notifications"
            d="M12,3a7.5,7.5,0,0,1,7.5,7.5c0,7.046,1.5,8.25,1.5,8.25H3s1.5-1.916,1.5-8.25A7.5,7.5,0,0,1,12,3Z"
          />
        </svg>
      </div>
    </div>
  </div>
</div>
