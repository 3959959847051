<dialog
  #dialog
  class="flex max-h-[600px] min-w-[500px] max-w-[900px] flex-col overflow-y-auto overflow-x-hidden rounded-lg p-6 shadow-lg backdrop:bg-[rgba(0,0,0,0.5)]"
>
  <div class="flex flex-row pb-6">
    <div
      [title]="title"
      class="flex-1 flex items-center text-lg font-bold truncate {{
        titleColor
      }}"
    >
      {{ title }}
    </div>
    <div>
      <button
        (click)="close()"
        class="flex shrink-0 cursor-pointer items-center justify-center rounded-full text-gray-400 hover:text-gray-600 focus:text-gray-600 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-6 w-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  </div>
  <ng-content select="[body]"></ng-content>
</dialog>
