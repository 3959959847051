import { Types, Model, HydratedDocument } from 'mongoose';
import {
  CompanyAuthRole,
  DominionAuthRole,
  Permissions,
} from '../authorization/authorization.interfaces';
import {
  CompanyDept,
  DominionDept,
  ICompany,
  ICompanyDocument,
  IInvitedUserDocument,
  IInvitedUserRead,
  IRequestUserPayload,
} from '@dominion/interfaces';

//
//
// UTILITIES

export interface VReq {
  user: IRequestUserPayload;
}

export type UserType = 'internal' | 'external';

export interface IUserAuthentication {
  salt: string;
  hash: string;
  passwordResetCode: string | undefined;
  passwordResetDate: Date | undefined;
  emailConfirmed: boolean;
  emailConfirmationCode: string | undefined;
}

export interface IUserResourceAuth {
  roles: (DominionAuthRole | CompanyAuthRole)[];
  permissions: Permissions[];
}

// this stores all authorizations that the user possesses
// the key is a fully-qualified, hierarchical resource name
export interface IUserAuthorizations {
  [key: string]: IUserResourceAuth;
}

// used to indicate whether an external user is assigned to a module
export interface IExternalAssignments {
  companyId: string;
  modules: string[];
}

export type UserStatus = 'invited' | 'active';

//
//
// CORE USER

export interface IUserBase {
  _id: string;
  email: string;
  companies: string[];
  userType: UserType;
  status: UserStatus;
  external: {
    assignments: IExternalAssignments[];
  };
  internal: {
    assignments: string[];
  };
  isDeactivated: boolean;
  authorizations: IUserAuthorizations;
}

export interface IUser extends IUserBase {
  _id: string;
  firstName: string;
  lastName: string;
  status: 'active';
  userType: UserType;
  email: string;
  registeredOn: Date;
  loginDates: Date[];
  authentication: IUserAuthentication;
  jobTitle: string | undefined;
  department: DominionDept | CompanyDept;
  companies: string[];
  authorizations: IUserAuthorizations;
  internal: {
    assignments: string[];
  };
  external: {
    assignments: IExternalAssignments[];
  };
}

//
//
// MODEL INTERFACES

export interface IUserModelOverrides {
  _id: Types.ObjectId;
  companies: Types.Array<Types.ObjectId>;
  internal: {
    assignments: Types.Array<Types.ObjectId>;
  };
  external: {
    assignments: {
      companyId: Types.ObjectId;
      modules: Types.Array<Types.ObjectId>;
    }[];
  };
}

export interface IUserModel extends Model<IUser, object, IUserModelOverrides> {}

export interface IUserDocument
  extends HydratedDocument<IUser, IUserModelOverrides> {}

export interface IUserDocumentPop
  extends Omit<IUserDocument, 'companies' | 'internal'> {
  companies: Types.DocumentArray<ICompanyDocument>;
  internal: Types.DocumentArray<ICompanyDocument>;
}

//
//
//
//
// CRUD DERIVATIVES

//
//
// CREATE

export type UserCreatePickKeys =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'userType'
  | 'jobTitle'
  | 'department';
export interface IUserCreateDto extends Pick<IUser, UserCreatePickKeys> {
  password: string;
}

export interface IExternalUserInvite extends Pick<IUser, 'email'> {
  companyId: string;
}
//
//
// READ

export type IUserReadOmitKeys = 'auth';
export interface IUserReadBase extends Omit<IUser, IUserReadOmitKeys> {
  auth?: undefined;
}

export interface IUserReadPrivate
  extends Omit<IUserReadBase, 'companies' | 'internal' | 'authentication'> {
  companies: Pick<ICompany, '_id' | 'name'>[];
  internal: {
    assignments: Pick<ICompany, '_id' | 'name'>[];
  };
}

export type IUserReadPrivateBriefPickKeys =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'userType'
  | 'jobTitle'
  | 'isDeactivated'
  | 'department';
export interface IUserReadPrivateBrief
  extends Pick<IUserReadPrivate, IUserReadPrivateBriefPickKeys> {
  _id: string;
  invitedOn?: Date;
}

export interface IUserReadPrivateBriefMeta {
  count: number;
  users: IUserReadPrivateBrief[];
}

export interface IUserWithAuthorizations {
  _id: string | Types.ObjectId;
  authorizations: IUserAuthorizations;
}

export interface IDominionAssignableData {
  firstName: IUser['firstName'];
  lastName: IUser['lastName'];
  email: IUser['email'];
  _id: IUser['_id'];
}

export interface IDominionAssignables {
  projectmanagers: IDominionAssignableData[];
  deploymentmanagers: IDominionAssignableData[];
  deploymenttechnicians: IDominionAssignableData[];
}

// PUBLIC
export type UserReadPublicOmitKeys = 'dateCreated' | 'loginDates';
export interface IUserReadPublic
  extends Omit<IUserReadBase, UserReadPublicOmitKeys> {
  dateCreated?: undefined;
  loginDates?: undefined;
}

//
//
// UPDATE

export type UserUpdateOmitKeys =
  | '_id'
  | 'userType'
  | 'dateCreated'
  | 'loginDates';
export interface IUserUpdateDto
  extends Partial<Omit<IUser, UserUpdateOmitKeys>> {}

export interface IUserSetDominionAuthDto {
  userId: string;
  role: DominionAuthRole;
}

// PREDICATES

export function isActiveUserDocument(
  user: IUserDocument | IInvitedUserDocument,
): user is IUserDocument {
  return user.status === 'active';
}

export function isActiveUserRead(
  user: IUserReadPrivate | IInvitedUserRead,
): user is IUserReadPrivate {
  return user.status === 'active';
}
