<div class="font-lato p-5 pt-10 sm:pt-20" data-testid="initial-el">
  <div class="mx-auto flex h-full w-full max-w-lg flex-col">
    <div class="flex flex-col items-center text-center">
      <img width="64" height="64" src="./../../../assets/vox-logo.svg" />
      <div class="h-5"></div>
      <h1 class="text-dms-teal text-3xl font-bold tracking-tight sm:text-4xl">
        {{ heading }}
      </h1>
      @if (subheading) {
        <div class="h-2.5"></div>
        <h2 class="text-dms-grey text-sm font-light uppercase tracking-wider">
          {{ subheading }}
        </h2>
      }
      <div class="h-10"></div>
    </div>

    <ng-content></ng-content>

    <div class="flex-1"></div>

    <div class="flex items-end justify-center pt-12 text-xs text-gray-400">
      {{ appVersion }}
    </div>
  </div>
</div>
