import {
  IsArray,
  IsIn,
  IsInstance,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import {
  IInvitedUser,
  IInvitedUserCreate,
  IInviteCompanyRole,
} from './invited-user.interfaces';
import { CompanyAuthRole } from '../authorization/authorization.interfaces';
import { Type } from 'class-transformer';
import 'reflect-metadata';

export class InviteCompanyRole implements IInviteCompanyRole {
  @IsString()
  companyId: string;
  @IsString()
  @IsIn(['super', 'admin', 'manager'])
  role: CompanyAuthRole;
}

export class InvitedUserDto implements IInvitedUserCreate {
  @IsString()
  email: string;
  @ValidateNested({ each: true })
  @Type(() => InviteCompanyRole)
  companyRoles: InviteCompanyRole[];
  @IsString()
  @IsIn(['all', 'one'])
  @IsOptional()
  companyStrategy: 'all' | 'one';
}
