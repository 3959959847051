import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {} from '../../validators/password-match.validator';
import { passwordMatchValidator } from '../../validators/password-match.validator';
import { Observer } from 'rxjs';
import { ResetPasswordDto } from '@dominion/interfaces';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthRootComponent } from '../auth-root/auth-root.component';
import { IconEyeOpenComponent } from '../../icons/icon-eye-open.component';
import { IconEyeClosedComponent } from '../../icons/icon-eye-closed.component';
import {
  passwordErrorMessages,
  passwordRequirementValidator,
} from '../../validators/password-requirement.validator';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';

@Component({
  selector: 'dominion-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterLink,
    AuthRootComponent,
    IconEyeOpenComponent,
    IconEyeClosedComponent,
    InformationButtonComponent,
  ],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('password') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('confirm') confirmInput: ElementRef;

  public _id: string;
  public token: string;
  public isErr: boolean;
  public errMsg: string;
  public showPassword = false;
  public successful = false;

  public passwordForm: FormGroup;
  private timeout: NodeJS.Timeout;

  private observer: Partial<Observer<void>> = {
    next: () => this.handleSuccess(),
    error: (err) => this.handleErr(err),
  };

  constructor(
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {
    this.passwordForm = this.fb.group(
      {
        password: ['', [Validators.required, passwordRequirementValidator()]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      { validators: passwordMatchValidator },
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this._id = params['_id'];
      this.token = params['token'];
    });
  }

  handleSuccess() {
    this.successful = true;
    this.timeout = setTimeout(() => {
      this.routeToLogin();
    }, 3000);
  }

  private routeToLogin() {
    this.router.navigateByUrl('login');
  }

  private handleErr(err: HttpErrorResponse) {
    this.passwordForm.reset();
    this.spinner.hide();
    this.isErr = true;
    this.errMsg = err.error.message;
  }

  private clearErr() {
    this.isErr = false;
    this.errMsg = '';
  }

  submit() {
    if (this.passwordForm.valid) {
      this.clearErr();
      this.spinner.show();
      const dto: ResetPasswordDto = {
        userId: this._id,
        resetToken: this.token,
        password: this.passwordForm.value.password,
      };
      this.authService.sendResetPassword(dto).subscribe(this.observer);
    }
  }

  togglePassword() {
    if (this.showPassword) {
      this.showPassword = false;
      this.confirmInput.nativeElement.setAttribute('type', 'password');
      this.passwordInput.nativeElement.setAttribute('type', 'password');
    } else {
      this.showPassword = true;
      this.confirmInput.nativeElement.setAttribute('type', 'text');
      this.passwordInput.nativeElement.setAttribute('type', 'text');
    }
  }

  getPasswordErrorMessage() {
    const control = this.passwordForm.get('password');
    if (control?.hasError('required')) {
      return passwordErrorMessages['required'];
    }
    if (control?.hasError('passwordRequirements')) {
      const firstErrorKey = Object.keys(
        control.errors?.['passwordRequirements'],
      )[0] as keyof typeof passwordErrorMessages;
      return passwordErrorMessages[firstErrorKey];
    }
    return null;
  }
}
