<div class="flex flex-row justify-center">
  <div class="flex max-w-[400px] flex-1 flex-col">
    <dominion-dropdown-search
      [options]="options"
      [selectedOption]="selectedOption"
      (emitSelectedValue)="selectResponse($event)"
    ></dominion-dropdown-search>
    <div class="mt-8 flex flex-row justify-end">
      <button
        dominionDiscoveryNextBtn
        type="submit"
        (click)="saveResponse()"
        [ngClass]="{ disabled: !selectedOption }"
        class=""
      >
        Next
      </button>
    </div>
  </div>
</div>
