<div class="relative">
  <label
    for="question-search"
    class="focus-within:border-dms-green flex w-full items-center rounded-sm border pl-2 text-sm transition hover:shadow"
  >
    <div class="h-4 w-4">
      <dominion-icon-search></dominion-icon-search>
    </div>
    <span class="sr-only">Search all questions</span>
    <input
      id="question-search"
      type="search"
      [(ngModel)]="search"
      placeholder="Search all questions..."
      class="h-7 w-full bg-transparent px-2 placeholder:text-sm focus:outline-none"
    />
  </label>

  @if (filteredQuestions.length > 0 && search.length > 0) {
    <ul
      class="absolute right-0 top-10 max-h-[420px] w-full overflow-y-auto rounded border bg-white px-1.5 shadow-xl"
    >
      @for (question of filteredQuestions; track question.groupKey) {
        <li class="py-1.5">
          <button
            (click)="selectQuestion(question)"
            class="w-full p-1.5 text-left hover:bg-gray-100"
          >
            <p class="truncate text-sm font-medium">
              {{ question.questionLabel }}
            </p>
            <p class="truncate text-xs text-gray-500">
              {{ question.questionPrompt }}
            </p>
          </button>
        </li>
        <hr class="mx-1.5 last:hidden" />
      }
    </ul>
  }
</div>
