import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import {
  IInvitedUserMeta,
  IInvitedUserReadBrief,
  IUserReadPrivate,
  IUserReadPrivateBrief,
  IUserReadPrivateBriefMeta,
  InvitedUserDto,
  UserDominionAuthDto,
  UserUpdateDto,
} from '@dominion/interfaces';

const apiBaseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
  ) {}

  getFullUser(_id: string): Observable<IUserReadPrivateBrief> {
    return this.http.get<IUserReadPrivateBrief>(
      `${apiBaseUrl}users/${_id}`,
      this.auth.httpOptions,
    );
  }

  updateUser(_id: string, dto: UserUpdateDto): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}users/${_id}`,
      dto,
      this.auth.httpOptions,
    );
  }

  getUsers(page: number): Observable<IUserReadPrivateBriefMeta> {
    return this.http.get<IUserReadPrivateBriefMeta>(
      `${apiBaseUrl}users/page/${page}`,
      this.auth.httpOptions,
    );
  }

  getInvitedUsers(page: number): Observable<IInvitedUserMeta> {
    return this.http.get<IInvitedUserMeta>(
      `${apiBaseUrl}users/invited/${page}`,
      this.auth.httpOptions,
    );
  }

  getUserDetail(id: string): Observable<IUserReadPrivate> {
    return this.http.get<IUserReadPrivate>(
      `${apiBaseUrl}users/user-detail/${id}`,
      this.auth.httpOptions,
    );
  }

  addUserToCompany(
    dto: InvitedUserDto,
  ): Observable<void | { inviteId: string }> {
    return this.http.post<void | { inviteId: string }>(
      `${apiBaseUrl}users/add-user-to-companies`,
      dto,
      this.auth.httpOptions,
    );
  }

  setDominionAuthorization(dto: UserDominionAuthDto): Observable<void> {
    return this.http.post<void>(
      `${apiBaseUrl}users/dominion-authorization`,
      dto,
      this.auth.httpOptions,
    );
  }

  roughDeleteUser(id: string): Observable<void> {
    return this.http.delete<void>(
      `${apiBaseUrl}users/${id}`,
      this.auth.httpOptions,
    );
  }

  deactivateUser(id: string): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}auth/deactivate-account/${id}`,
      {},
      this.auth.httpOptions,
    );
  }

  reactivateUser(id: string): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}auth/reactivate-account/${id}`,
      {},
      this.auth.httpOptions,
    );
  }

}
