import { Model, Types } from 'mongoose';
import { IVendor } from './vendor.interfaces';
import { IsIn, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ModuleType } from '../modules/core/core-module.interfaces';

export interface IVendorReference {
  vendorId: string;
  appName: IVendor['appName'];
  providerName: IVendor['providerName'];
}

export interface IVendorQuestion {
  _id: string;
  questionKey: string;
  moduleType: ModuleType;
  label: string;
  desc: string;
  vendors: IVendorReference[];
}

export interface IVendorQuestionModelOverrides {
  _id: Types.ObjectId;
  vendors: Types.DocumentArray<
    Pick<IVendorReference, 'appName' | 'providerName'> & {
      vendorId: Types.ObjectId;
    }
  >;
}

export interface IVendorQuestionModel
  extends Model<IVendorQuestion, {}, IVendorQuestionModelOverrides> {}

// CREATE

export class VendorQuestionCreateDto {
  _id: never;
  @IsNotEmpty()
  @IsIn(['parts', 'service', 'accounting', 'payroll', 'fi', 'sales'])
  moduleType: IVendorQuestion['moduleType'];
  @IsString()
  @IsNotEmpty()
  questionKey: IVendorQuestion['questionKey'];
  @IsString()
  @IsNotEmpty()
  label: IVendorQuestion['label'];
  @IsString()
  @IsOptional()
  desc?: IVendorQuestion['desc'];
}

// UPDATE

export class VendorQuestionUpdateDto {
  @IsString()
  @IsNotEmpty()
  vendorId: string;
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  questionKey: IVendorQuestion['questionKey'];
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  label: IVendorQuestion['label'];
  @IsString()
  @IsOptional()
  desc?: IVendorQuestion['desc'];
}

export class VendorQuestionUpdateVendorsDto {
  @IsString()
  @IsNotEmpty()
  vendorQuestionId: string;
  @IsString()
  @IsNotEmpty()
  vendorId: string;
}

// READ

export class VendorQuestionGetByKeysDto {
  @IsString({ each: true })
  @IsNotEmpty({ each: true })
  questionKeys: IVendorQuestion['questionKey'][];
}
