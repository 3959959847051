<dominion-discovery-side-nav></dominion-discovery-side-nav>
<div class="flex h-full w-full flex-col overflow-hidden pb-12">
  <div class="flex shrink flex-row items-center space-x-2 pb-7 pl-4 pt-4">
    <div class="text-dms-teal text-xl font-bold">Vendors</div>
    <dominion-information-button [buttonHeight]="'h-5'" [buttonWidth]="'w-5'">
      <div class="flex w-64 flex-col space-y-2 p-2">
        <p>
          <strong>Vendors</strong> are third parties that you use for various
          services, like CRMs and electronic parts catalogs. Often, these
          vendors integrate with your DMS.
        </p>
        <p>
          The discovery modules will ask which vendors you use for various
          services. When you indicate that you use a particular vendor, it will
          show up here. So, this is a comprehensive list of all the vendors that
          you say you use.
        </p>
      </div>
    </dominion-information-button>
    <div class="flex flex-row space-x-1 pr-1">
      @if (canAuthorizeVendors) {
        <button
          class="bg-dms-teal hover:ring-dms-light-green ml-10 flex flex-row items-center space-x-3 rounded px-2 py-1 text-white outline-none transition-all first:rounded-br-none first:rounded-tr-none hover:ring-2 hover:ring-offset-1"
          (click)="authorizationModal.open()"
        >
          <span>Authorize Vendors</span>
          <div class="h-4 w-4 text-white">
            <dominion-icon-go-to
              [strokeColor]="'stroke-white'"
            ></dominion-icon-go-to>
          </div>
        </button>
      }

      <button
        class="border-dms-teal text-dms-teal hover:ring-dms-light-green ml-2 mr-1 flex flex-row items-center justify-center space-x-3 rounded border px-2 py-1 outline-none transition-all last:rounded-bl-none last:rounded-tl-none first:last:rounded-bl first:last:rounded-tl hover:ring-2 hover:ring-offset-1"
        (click)="historicalModal.open()"
      >
        <span class="flex-none">View Authorizations</span>
      </button>
    </div>
  </div>

  <div class="flex flex-1 flex-col space-y-6 overflow-hidden pb-6">
    <!-- CUSTOM VENDORS -->
    <div
      class="mx-4 flex flex-auto basis-1/3 flex-col overflow-hidden rounded-sm border border-gray-100 shadow-lg"
    >
      <div class="flex shrink flex-row items-center space-x-2 p-4">
        <span class="text-sm uppercase text-gray-500">Custom Vendors</span>
        <dominion-information-button
          [buttonHeight]="'h-4'"
          [buttonWidth]="'w-4'"
        >
          <div class="w-64 p-2">
            <p>
              <strong>Custom vendors</strong> are vendors that you said you use
              but that are not currently in our vendor system.
            </p>
          </div>
        </dominion-information-button>
      </div>

      <dominion-table
        [hideFrame]="true"
        [isLoading]="isLoadingVendors"
        [isEmpty]="customVendors.length === 0"
      >
        <thead>
          <tr>
            <th scope="col">Provider Name</th>
            <th scope="col">Application Name</th>
          </tr>
        </thead>
        <tbody>
          @for (vendor of customVendors; track vendor._id) {
            <tr>
              <td>
                @if (vendor.providerName) {
                  <span class="font-semibold">{{ vendor.providerName }}</span>
                } @else {
                  <span class="text-gray-400">&mdash;</span>
                }
              </td>
              <td>
                @if (vendor.appName) {
                  {{ vendor.appName }}
                } @else {
                  <span class="text-gray-400">&mdash;</span>
                }
              </td>
            </tr>
          }
        </tbody>
      </dominion-table>
    </div>

    <!-- STANDARD VENDORS -->
    <div
      class="mx-4 flex flex-auto basis-2/3 flex-col overflow-hidden rounded-sm border border-gray-100 shadow-lg"
    >
      <div class="flex shrink flex-row items-center space-x-2 p-4">
        <span class="text-sm uppercase text-gray-500">Standard Vendors</span>
        <dominion-information-button
          [buttonHeight]="'h-4'"
          [buttonWidth]="'w-4'"
        >
          <div class="w-64 p-2">
            <p>
              <strong>Standard vendors</strong> are vendors that we have in our
              system and that you said you use for one or more services.
            </p>
          </div>
        </dominion-information-button>
      </div>

      <dominion-table
        [hideFrame]="true"
        [isLoading]="isLoadingVendors"
        [isEmpty]="vendors.length === 0"
      >
        <thead>
          <tr>
            <th scope="col">Provider Name</th>
            <th scope="col">Application Name</th>
            <th scope="col">
              <div class="flex items-center gap-2">
                Authorized
                <dominion-information-button
                  [buttonWidth]="'w-4'"
                  [buttonHeight]="'h-4'"
                >
                  <div
                    class="flex w-64 flex-col space-y-2 text-sm font-normal normal-case"
                  >
                    <p>
                      A vendor is <strong>authorized</strong> if you have
                      confirmed that the vendor should have access to your data
                      in VUE.
                    </p>
                    <p>
                      You can authorize your vendors by clicking "Authorize
                      Vendors" at the top of this page.
                    </p>
                  </div>
                </dominion-information-button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          @for (vendor of vendors; track vendor._id) {
            <tr>
              <td>
                @if (vendor.providerName) {
                  <span class="font-semibold">{{ vendor.providerName }}</span>
                } @else {
                  <span class="text-gray-400">&mdash;</span>
                }
              </td>
              <td>{{ vendor.appName }}</td>
              <td>
                @if (vendor | isVendorAuthorized: vendorAuthorizations[0]) {
                  <div class="h-5 w-5">
                    <dominion-icon-check-circle></dominion-icon-check-circle>
                  </div>
                } @else {
                  <div class="h-5 w-5">
                    <dominion-icon-x-circle></dominion-icon-x-circle>
                  </div>
                }
              </td>
            </tr>
          }
        </tbody>
      </dominion-table>
    </div>
  </div>
</div>

<!--  -->
<!--  -->
<!-- AUTHORIZATION MODAL -->
<dominion-modal #authorizationModal [title]="'Authorize Vendors'">
  <div
    body
    class="flex h-[750px] w-[700px] flex-col space-y-10 overflow-y-scroll pb-4"
  >
    <div class="flex flex-row justify-center">
      <p class="mt-6 max-w-[500px] text-center text-lg">
        You are authorizing the following vendors to have access to your data.
        Your authorization will allow these vendors to integrate with VUE.
      </p>
    </div>

    <div class="flex flex-row justify-center">
      <div
        class="flex max-w-[475px] flex-col rounded-sm border border-gray-200 p-4"
      >
        @for (vendor of vendors; track vendor._id) {
          <div class="flex flex-row text-sm">
            <span class="font-bold">{{ vendor.providerName }}</span>
            <span class="ml-2">{{ vendor.appName }}</span>
          </div>
        }
      </div>
    </div>

    <p class="mx-20 text-xs">
      By clicking “submit”, (1) you represent and warrant that (a) you are
      acting on behalf of the dealership listed above (“Dealer”) (and are
      authorized to act in that capacity) and (b) Dealer has obtained and will
      maintain any and all consents, licenses, or permits (including consent
      from Dealer’s customers) that are legally required to enable Dominion DMS,
      LLC to share the data elements listed above (“Dealer Data”) with third
      parties; and (2) you hereby authorize Dominion DMS to share the Dealer
      Data with the third party vendor(s) you have selected and in the manner
      you have indicated, and hereby release and agree to indemnify, defend, and
      hold harmless Dominion DMS, its affiliates, and its and their officers,
      managers, members, and employees, from any and all claims, liabilities,
      losses, costs, expenses, and damages that may result from the transfer of
      the Dealer Data hereby authorized or the revocation of one or more
      vendor’s access to or receipt of such Dealer Data. The foregoing shall not
      limit Dealer’s obligations or liability under the Master Terms and
      Conditions or other contract between Dealer and Dominion DMS.
    </p>

    <div class="flex flex-row justify-center">
      <button
        (click)="authorizeVendors()"
        class="bg-dms-teal hover:ring-dms-light-green rounded p-2 px-8 text-center text-base text-white hover:ring-2 hover:ring-offset-1"
      >
        Submit
      </button>
    </div>
  </div>
</dominion-modal>

<!--  -->
<!--  -->
<!-- HISTORICAL AUTHORIZATIONS MODAL -->
<dominion-modal #historicalModal [title]="'Vendor Authorizations'">
  <div body class="flex h-[700px] w-[600px] flex-col overflow-y-scroll p-4">
    @if (vendorAuthorizations && vendorAuthorizations.length > 0) {
      <!-- ACTIVE AUTHORIZATION -->
      <div
        class="my-4 mb-8 flex flex-row rounded-sm border border-gray-200 p-4 shadow-lg"
      >
        <div class="flex flex-1 flex-col">
          <div
            class="mb-2 flex flex-row justify-center uppercase text-gray-600"
          >
            Active Authorization
          </div>

          <!-- USER NAME AND TITLE -->
          <div class="mt-3 flex flex-row justify-center space-x-1 font-bold">
            <span> {{ vendorAuthorizations[0].user.firstName }} </span>
            <span>{{ vendorAuthorizations[0].user.lastName + ', ' }}</span>
            <span class="font-normal">{{
              vendorAuthorizations[0].user.jobTitle
            }}</span>
          </div>

          <!-- USER EMAIL -->
          <div class="flex flex-row justify-center text-sm text-gray-600">
            {{ vendorAuthorizations[0].user.email }}
          </div>

          <!-- AUTHORIZATION DATE -->
          <div class="flex flex-row justify-center text-sm text-gray-600">
            {{ vendorAuthorizations[0].date | date: 'medium' }}
          </div>

          <!-- AUTHORIZED VENDORS -->
          <div class="mt-4 flex flex-col bg-blue-50 py-4">
            <div
              class="text-dms-teal mb-2 flex flex-row items-center justify-center uppercase"
            >
              <span class="mr-1"
                >Authorized Vendors &lpar;{{
                  vendorAuthorizations[0].vendors.length
                }}&rpar;
              </span>
              <dominion-information-button
                [strokeColor]="'stroke-gray-600 hover:stroke-white'"
              >
                <div class="flex w-60 flex-col normal-case">
                  <p>
                    This section contains <strong>all</strong> of the vendors
                    authorized by this authorization.
                  </p>
                </div>
              </dominion-information-button>
            </div>
            <div class="flex flex-col">
              @for (
                vendor of vendorAuthorizations[0].vendors;
                track vendor._id
              ) {
                <div class="flex flex-row space-x-2">
                  <div class="flex basis-1/2 justify-end font-bold">
                    {{ vendor.providerName }}
                  </div>
                  <div class="basis-1/2">
                    {{ vendor.appName }}
                  </div>
                </div>
              }
            </div>
          </div>

          <!-- VENDORS ADDED -->
          <div class="mt-4 flex flex-col bg-green-50 py-4">
            <div
              class="text-dms-green mb-2 flex flex-row items-center justify-center uppercase"
            >
              <span class="mr-1"
                >Added Vendors &lpar;{{
                  vendorAuthorizations[0].vendorsAdded.length
                }}&rpar;</span
              >
              <dominion-information-button
                [strokeColor]="'stroke-gray-600 hover:stroke-white'"
              >
                <div class="flex w-60 flex-col normal-case">
                  <p>
                    This section contains any vendors that were
                    <strong>added</strong> relative to the last authorization.
                  </p>
                </div>
              </dominion-information-button>
            </div>
            <div class="flex flex-col">
              @for (
                vendor of vendorAuthorizations[0].vendorsAdded;
                track vendor._id
              ) {
                <div class="flex flex-row space-x-2">
                  <div class="flex basis-1/2 justify-end font-bold">
                    {{ vendor.providerName }}
                  </div>
                  <div class="basis-1/2">
                    {{ vendor.appName }}
                  </div>
                </div>
              } @empty {
                <div class="flex flex-row justify-center italic text-gray-600">
                  No Vendors Added
                </div>
              }
            </div>
          </div>

          <!-- VENDORS REMOVED -->
          <div class="mt-4 flex flex-col bg-red-50 py-4">
            <div
              class="mb-2 flex flex-row items-center justify-center uppercase text-red-800"
            >
              <span class="mr-1"
                >Removed Vendors &lpar;{{
                  vendorAuthorizations[0].vendorsRemoved.length
                }}&rpar;</span
              >
              <dominion-information-button
                [strokeColor]="'stroke-gray-600 hover:stroke-white'"
              >
                <div class="flex w-60 flex-col normal-case">
                  <p>
                    This section contains any vendors that were
                    <strong>removed</strong> relative to the last authorization.
                  </p>
                </div>
              </dominion-information-button>
            </div>
            <div class="flex flex-col">
              @for (
                vendor of vendorAuthorizations[0].vendorsRemoved;
                track vendor._id
              ) {
                <div class="flex flex-row space-x-2">
                  <div class="flex basis-1/2 justify-end font-bold">
                    {{ vendor.providerName }}
                  </div>
                  <div class="basis-1/2">
                    {{ vendor.appName }}
                  </div>
                </div>
              } @empty {
                <div class="flex flex-row justify-center italic text-gray-600">
                  No Vendors Removed
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <!-- PRIOR AUTHORIZATIONS -->
      <div
        class="flex flex-col space-y-4 rounded-sm border border-gray-200 shadow-lg"
      >
        <div class="mt-4 flex flex-row justify-center uppercase text-gray-600">
          Prior Authorizations
        </div>

        <div class="group flex flex-1 flex-col">
          @for (
            vendorAuth of vendorAuthorizations;
            track vendorAuth.date;
            let i = $index
          ) {
            @if (i === 0) {
            } @else {
              <div
                class="hover:shadow-centered flex flex-row border-b border-gray-100 first:border-t hover:border-gray-200 group-hover:border-gray-200 group-hover:bg-gray-100 group-hover:hover:bg-white"
              >
                <div dominionExpandableHost class="flex flex-1 flex-col p-2">
                  <div
                    dominionExpandableToggle
                    class="flex cursor-pointer flex-row items-center justify-center"
                  >
                    <div class="mr-2 h-4 w-4">
                      <dominion-dropdown-caret
                        [stroke]="'stroke-gray-600'"
                      ></dominion-dropdown-caret>
                    </div>
                    {{ vendorAuth.date | date: 'medium' }}
                  </div>
                  <div dominionExpandable class="flex flex-row">
                    <div class="flex flex-1 flex-col">
                      <!-- AUTHORIZED VENDORS -->
                      <div class="mt-4 flex flex-col bg-blue-50 py-4">
                        <div
                          class="text-dms-teal mb-2 flex flex-row items-center justify-center uppercase"
                        >
                          <span class="mr-1"
                            >Authorized Vendors &lpar;{{
                              vendorAuth.vendors.length
                            }}&rpar;
                          </span>
                          <dominion-information-button
                            [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                          >
                            <div class="flex w-60 flex-col normal-case">
                              <p>
                                This section contains <strong>all</strong> of
                                the vendors authorized by this authorization.
                              </p>
                            </div>
                          </dominion-information-button>
                        </div>
                        <div class="flex flex-col">
                          @for (
                            vendor of vendorAuth.vendors;
                            track vendor._id
                          ) {
                            <div class="flex flex-row space-x-2">
                              <div class="flex basis-1/2 justify-end font-bold">
                                {{ vendor.providerName }}
                              </div>
                              <div class="basis-1/2">
                                {{ vendor.appName }}
                              </div>
                            </div>
                          }
                        </div>
                      </div>

                      <!-- VENDORS ADDED -->
                      <div class="mt-4 flex flex-col bg-green-50 py-4">
                        <div
                          class="text-dms-green mb-2 flex flex-row items-center justify-center uppercase"
                        >
                          <span class="mr-1"
                            >Added Vendors &lpar;{{
                              vendorAuth.vendorsAdded.length
                            }}&rpar;</span
                          >
                          <dominion-information-button
                            [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                          >
                            <div class="flex w-60 flex-col normal-case">
                              <p>
                                This section contains any vendors that were
                                <strong>added</strong> relative to the last
                                authorization.
                              </p>
                            </div>
                          </dominion-information-button>
                        </div>
                        <div class="flex flex-col">
                          @for (
                            vendor of vendorAuth.vendorsAdded;
                            track vendor._id
                          ) {
                            <div class="flex flex-row space-x-2">
                              <div class="flex basis-1/2 justify-end font-bold">
                                {{ vendor.providerName }}
                              </div>
                              <div class="basis-1/2">
                                {{ vendor.appName }}
                              </div>
                            </div>
                          } @empty {
                            <div
                              class="flex flex-row justify-center italic text-gray-600"
                            >
                              No Vendors Added
                            </div>
                          }
                        </div>
                      </div>

                      <!-- VENDORS REMOVED -->
                      <div class="mt-4 flex flex-col bg-red-50 py-4">
                        <div
                          class="mb-2 flex flex-row items-center justify-center uppercase text-red-800"
                        >
                          <span class="mr-1"
                            >Removed Vendors &lpar;{{
                              vendorAuth.vendorsRemoved.length
                            }}&rpar;</span
                          >
                          <dominion-information-button
                            [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                          >
                            <div class="flex w-60 flex-col normal-case">
                              <p>
                                This section contains any vendors that were
                                <strong>removed</strong> relative to the last
                                authorization.
                              </p>
                            </div>
                          </dominion-information-button>
                        </div>
                        <div class="flex flex-col">
                          @for (
                            vendor of vendorAuth.vendorsRemoved;
                            track vendor._id
                          ) {
                            <div class="flex flex-row space-x-2">
                              <div class="flex basis-1/2 justify-end font-bold">
                                {{ vendor.providerName }}
                              </div>
                              <div class="basis-1/2">
                                {{ vendor.appName }}
                              </div>
                            </div>
                          } @empty {
                            <div
                              class="flex flex-row justify-center italic text-gray-600"
                            >
                              No Vendors Removed
                            </div>
                          }
                        </div>
                      </div>
                      <div class="flex h-4 w-full flex-none"></div>
                    </div>
                  </div>
                </div>
              </div>
            }
          }

          @if (vendorAuthorizations.length === 1) {
            <div class="flex flex-row justify-center italic text-gray-600">
              No Prior Authorizations
            </div>
          }
        </div>
      </div>
    } @else {
      <div
        class="mt-12 flex flex-row justify-center text-lg italic text-gray-700"
      >
        No authorizations found
      </div>
    }
  </div>
</dominion-modal>
