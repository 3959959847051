<div
  routerLink="/core/admin/dashboard"
  routerLinkActive="[&>[icon]]:text-white [&>[icon]]:bg-dms-mid-green"
  class="hover:bg-dms-light-grey flex cursor-pointer flex-row items-center gap-2 p-2"
>
  <div icon class="relative h-7 w-7 rounded-sm p-1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>
          .i-dashboard {
            fill: none;
            stroke: currentColor;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <title>Home</title>
      <path
        class="i-dashboard"
        d="M22.868,8.947,12,.747,1.122,8.947a1.177,1.177,0,0,0-.377.8V22.269a.981.981,0,0,0,.978.978H8.245V18a3.75,3.75,0,0,1,7.5,0v5.25h6.521a.982.982,0,0,0,.979-.978V9.747A1.181,1.181,0,0,0,22.868,8.947Z"
      />
    </svg>
  </div>
  Dashboard
</div>
<div
  routerLink="/core/admin/companies"
  routerLinkActive="[&>[icon]]:text-white [&>[icon]]:bg-dms-mid-green"
  class="hover:bg-dms-light-grey flex cursor-pointer flex-row items-center gap-2 p-2"
>
  <div icon class="relative h-7 w-7 rounded-sm p-1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>
          .i-companies {
            fill: none;
            stroke: currentColor;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <title>Company</title>
      <line class="i-companies" x1="20.25" y1="3.75" x2="20.25" y2="23.25" />
      <line class="i-companies" x1="3.75" y1="3.75" x2="20.25" y2="3.75" />
      <line class="i-companies" x1="3.75" y1="23.25" x2="3.75" y2="3.75" />
      <line class="i-companies" x1="20.25" y1="23.25" x2="3.75" y2="23.25" />
      <polygon
        class="i-companies"
        points="20.25 3.75 3.75 3.75 5.25 0.75 18.75 0.75 20.25 3.75"
      />
      <line class="i-companies" x1="0.75" y1="23.25" x2="23.25" y2="23.25" />
      <path class="i-companies" d="M14.25,21a2.25,2.25,0,0,0-4.5,0v2.25h4.5Z" />
      <path class="i-companies" d="M7.5,6.375a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M7.125,6.75A.375.375,0,0,1,7.5,6.375" />
      <path class="i-companies" d="M7.5,7.125a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M7.875,6.75a.375.375,0,0,1-.375.375" />
      <path class="i-companies" d="M7.5,10.875a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M7.125,11.25a.375.375,0,0,1,.375-.375" />
      <path class="i-companies" d="M7.5,11.625a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M7.875,11.25a.375.375,0,0,1-.375.375" />
      <path class="i-companies" d="M7.5,15.375a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M7.125,15.75a.375.375,0,0,1,.375-.375" />
      <path class="i-companies" d="M7.5,16.125a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M7.875,15.75a.375.375,0,0,1-.375.375" />
      <path class="i-companies" d="M12,6.375a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M11.625,6.75A.375.375,0,0,1,12,6.375" />
      <path class="i-companies" d="M12,7.125a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M12.375,6.75A.375.375,0,0,1,12,7.125" />
      <path class="i-companies" d="M12,10.875a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M11.625,11.25A.375.375,0,0,1,12,10.875" />
      <path class="i-companies" d="M12,11.625a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M12.375,11.25a.375.375,0,0,1-.375.375" />
      <path class="i-companies" d="M12,15.375a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M11.625,15.75A.375.375,0,0,1,12,15.375" />
      <path class="i-companies" d="M12,16.125a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M12.375,15.75a.375.375,0,0,1-.375.375" />
      <path class="i-companies" d="M16.5,6.375a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M16.125,6.75a.375.375,0,0,1,.375-.375" />
      <path class="i-companies" d="M16.5,7.125a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M16.875,6.75a.375.375,0,0,1-.375.375" />
      <path class="i-companies" d="M16.5,10.875a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M16.125,11.25a.375.375,0,0,1,.375-.375" />
      <path class="i-companies" d="M16.5,11.625a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M16.875,11.25a.375.375,0,0,1-.375.375" />
      <path class="i-companies" d="M16.5,15.375a.375.375,0,0,1,.375.375" />
      <path class="i-companies" d="M16.125,15.75a.375.375,0,0,1,.375-.375" />
      <path class="i-companies" d="M16.5,16.125a.375.375,0,0,1-.375-.375" />
      <path class="i-companies" d="M16.875,15.75a.375.375,0,0,1-.375.375" />
    </svg>
  </div>
  Companies
</div>
<div
  routerLink="/core/admin/users"
  routerLinkActive="[&>[icon]]:text-white [&>[icon]]:bg-dms-mid-green"
  class="hover:bg-dms-light-grey flex cursor-pointer flex-row items-center gap-2 p-2"
>
  <div icon class="relative h-7 w-7 rounded-sm p-1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>
          .i-user {
            fill: none;
            stroke: currentColor;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <title>User</title>
      <path
        class="i-user"
        d="M12.138,5.25S10.7,8.847,7.107,8.995a5.25,5.25,0,1,0,10.062,0C13.571,8.847,12.138,5.25,12.138,5.25Z"
      />
      <path
        class="i-user"
        d="M17.974,19.026a14.584,14.584,0,0,0,3.391-1.006,1.5,1.5,0,0,0,.762-1.961l-1.375-3.21a4.5,4.5,0,0,1-.364-1.773V9a8.25,8.25,0,0,0-16.5,0v2.076a4.5,4.5,0,0,1-.364,1.773l-1.376,3.21A1.5,1.5,0,0,0,2.91,18.02,14.611,14.611,0,0,0,6.3,19.026"
      />
      <path class="i-user" d="M21.614,23.25a10.483,10.483,0,0,0-18.952,0" />
      <path
        class="i-user"
        d="M16.422,18.164c-1.284,2.086-4.284,2.086-4.284,5.086,0-3-3-3-4.284-5.086"
      />
    </svg>
  </div>
  Users
</div>
<div
  routerLink="/core/admin/vendors"
  routerLinkActive="[&>[icon]]:text-white [&>[icon]]:bg-dms-mid-green"
  class="hover:bg-dms-light-grey flex cursor-pointer flex-row items-center gap-2 p-2"
>
  <div icon class="relative h-7 w-7 rounded-sm p-1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>
          .i-vendor {
            fill: none;
            stroke: currentColor;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <title>Vendors</title>
      <line class="i-vendor" x1="18.75" y1="14.004" x2="13.963" y2="14.961" />
      <path
        class="i-vendor"
        d="M14.438,9.531l-2.26.955a1.42,1.42,0,0,1-1.122-.016,1.478,1.478,0,0,1-.781-.83,1.525,1.525,0,0,1,.706-1.9L13.24,6.613a1.961,1.961,0,0,1,1.594-.094l4.639,1.828"
      />
      <path
        class="i-vendor"
        d="M5.2,15h1.6l3.049,2.326a.83.83,0,0,0,1.15.09l4.26-3.518a.842.842,0,0,0,.129-1.164l-2.347-2.609"
      />
      <path
        class="i-vendor"
        d="M10.736,7.9l-.247-.2a1.787,1.787,0,0,0-1.725-.221L5.194,9"
      />
      <path
        class="i-vendor"
        d="M1.39,15.75H4.05a1.141,1.141,0,0,0,1.2-1.072V9.321A1.141,1.141,0,0,0,4.05,8.25H1.39"
      />
      <path
        class="i-vendor"
        d="M22.61,15.75H19.95a1.141,1.141,0,0,1-1.2-1.072V9.321a1.141,1.141,0,0,1,1.2-1.071h2.66"
      />
      <circle class="i-vendor" cx="12" cy="12" r="11.25" />
    </svg>
  </div>
  Vendors
</div>
<div
  routerLink="/core/admin/questions"
  routerLinkActive="[&>[icon]]:text-white [&>[icon]]:bg-dms-mid-green"
  class="hover:bg-dms-light-grey flex cursor-pointer flex-row items-center gap-2 p-2"
>
  <div icon class="relative h-7 w-7 rounded-sm p-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M2 7v10" />
      <path d="M6 5v14" />
      <rect width="12" height="18" x="10" y="3" rx="2" />
    </svg>
  </div>
  Questions
</div>
<div
  routerLink="/core/admin/support"
  routerLinkActive="[&>[icon]]:text-white [&>[icon]]:bg-dms-mid-green"
  class="hover:bg-dms-light-grey flex cursor-pointer flex-row items-center gap-2 p-2"
>
  <div icon class="relative h-7 w-7 rounded-sm p-1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>
          .i-support {
            fill: none;
            stroke: currentColor;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <title>Support</title>
      <path
        class="i-support"
        d="M15.322,2.549a8.989,8.989,0,0,1,2.251,16.5v4.2"
      />
      <circle class="i-support" cx="13.072" cy="11.25" r="3.75" />
      <path
        class="i-support"
        d="M10.822,8.25V1.5a.75.75,0,0,1,.75-.75h3a.75.75,0,0,1,.75.75V8.25"
      />
      <path
        class="i-support"
        d="M4.073,15.761l-.936-.234a1.5,1.5,0,0,1-.884-2.287l1.82-2.729v-.75c0-3.542,2.868-6.511,6.749-7.3"
      />
      <path
        class="i-support"
        d="M8.573,23.25V21.011l-1.007.167a2.978,2.978,0,0,1-1.244-.053"
      />
      <path class="i-support" d="M13.072,11.25a7.5,7.5,0,0,1-7.5,7.5h-1.5" />
    </svg>
  </div>
  Support
</div>
