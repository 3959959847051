<div
  class="text-dms-grey flex h-7 flex-row divide-x rounded-sm border text-xs tracking-wide"
>
  <div class="flex flex-1 flex-row items-center whitespace-nowrap">
    <span class="px-2">
      Page
      <span class="font-normal" data-testid="table-nav-current-page">{{
        page
      }}</span>
      of
      <span class="font-normal" data-testid="table-nav-total-pages">{{
        pages
      }}</span>
    </span>
  </div>
  <button
    class="enabled:hover:bg-dms-light-grey flex cursor-pointer flex-row items-center justify-center p-2 disabled:cursor-not-allowed disabled:text-gray-400"
    [disabled]="page === 1 || pages <= 1"
    (click)="first()"
    data-testid="table-nav-first-btn"
  >
    <div class="">
      <div class="h-3 w-3">
        <svg
          id="Regular"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-first-page {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
                fill-rule: evenodd;
              }
            </style>
          </defs>
          <title>First Page</title>
          <path
            class="i-first-page"
            d="M11.689,23.25.97,12.53a.749.749,0,0,1,0-1.06L11.689.75"
          />
          <path
            class="i-first-page"
            d="M22.939,23.25,12.22,12.53a.749.749,0,0,1,0-1.06L22.939.75"
          />
        </svg>
      </div>
    </div>
  </button>
  <button
    class="enabled:hover:bg-dms-light-grey flex cursor-pointer flex-row items-center justify-center p-2 disabled:cursor-not-allowed disabled:text-gray-400"
    [disabled]="page === 1 || pages <= 1"
    (click)="prev()"
    data-testid="table-nav-prev-btn"
  >
    <div class="">
      <div class="h-3 w-3">
        <svg
          id="Regular"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-prev-page {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
                fill-rule: evenodd;
              }
            </style>
          </defs>
          <title>Previous Page</title>
          <path
            class="i-prev-page"
            d="M16.25,23.25,5.53,12.53a.749.749,0,0,1,0-1.06L16.25.75"
          />
        </svg>
      </div>
    </div>
  </button>
  <button
    class="enabled:hover:bg-dms-light-grey flex cursor-pointer flex-row items-center justify-center p-2 disabled:cursor-not-allowed disabled:text-gray-400"
    [disabled]="page === pages || pages <= 1"
    (click)="next()"
    data-testid="table-nav-next-btn"
  >
    <div class="">
      <div class="h-3 w-3">
        <svg
          id="Regular"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-next-page {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
                fill-rule: evenodd;
              }
            </style>
          </defs>
          <title>Next Page</title>
          <path
            class="i-next-page"
            d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"
          />
        </svg>
      </div>
    </div>
  </button>
  <button
    class="enabled:hover:bg-dms-light-grey flex cursor-pointer flex-row items-center justify-center p-2 disabled:cursor-not-allowed disabled:text-gray-400"
    [disabled]="page === pages || pages <= 1"
    (click)="last()"
    data-testid="table-nav-last-btn"
  >
    <div class="">
      <div class="h-3 w-3">
        <svg
          id="Regular"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-last-page {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
                fill-rule: evenodd;
              }
            </style>
          </defs>
          <title>Last Page</title>
          <path
            class="i-last-page"
            d="M12.311.75,23.03,11.47a.749.749,0,0,1,0,1.06L12.311,23.25"
          />
          <path
            class="i-last-page"
            d="M1.061.75,11.78,11.47a.749.749,0,0,1,0,1.06L1.061,23.25"
          />
        </svg>
      </div>
    </div>
  </button>

  @if (results && resultsPerPage) {
    <div class="flex items-center p-2 text-xs tabular-nums text-gray-600">
      {{ getResultsInfo() }}
    </div>
  }
</div>
