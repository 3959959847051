import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CoreModuleAddUserDto,
  CoreModuleCreateDto,
  CoreModuleUpdateDto,
  ICompanyReadFull,
  ICoreModule,
  ICoreModuleUpdateEvent,
  ISubmoduleUpdateEvent,
  SubmoduleUpdateDto,
} from '@dominion/interfaces';
import { ModalComponent } from '../../shared/modal/modal.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';
import { Types } from 'mongoose';
import { HttpErrorResponse } from '@angular/common/http';
import { EditableSelectComponent } from '../../shared/editable-select/editable-select.component';
import { EditableTextComponent } from '../../shared/editable-text/editable-text.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CompletionStatusBarComponent } from '../../shared/completion-status-bar/completion-status-bar.component';
import { ExpandableDirective } from '../../shared/directives/expandable.directive';
import { ExpandableToggleDirective } from '../../shared/directives/expandable-toggle.directive';
import { ExpandableHostDirective } from '../../shared/directives/expandable-host.directive';
import { DropdownCaretComponent } from '../../shared/dropdown-caret/dropdown-caret.component';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';
import { ModuleStatusCircleComponent } from '../../shared/module-status-circle/module-status-circle.component';
import { ModuleAssignedUserPipe } from './module-assigned-user.pipe';
import { ModuleActiveAssignedUserPipe } from './module-active-assigned-user.pipe';
import { SubmoduleStatusPipe } from './submodule-status.pipe';
import { ModuleActionBtnComponent } from './module-action-btn.component';
import { ModuleCompletionPipe } from './module-completion.pipe';
import { ModuleTypePipe } from '../../shared/pipes/module-type.pipe';
import { parseISO, startOfDay } from 'date-fns';

export interface UpdateEmission<DTO> {
  dto: DTO;
  editable: EditableTextComponent | EditableSelectComponent;
}

@Component({
  selector: 'dominion-company-project',
  templateUrl: './company-project.component.html',
  styleUrls: ['./company-project.component.css'],
  imports: [
    SharedModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    CompletionStatusBarComponent,
    ExpandableDirective,
    ModuleStatusCircleComponent,
    ModuleAssignedUserPipe,
    ModuleCompletionPipe,
    ExpandableToggleDirective,
    SubmoduleStatusPipe,
    ExpandableHostDirective,
    ModuleActiveAssignedUserPipe,
    DropdownCaretComponent,
    ModuleActionBtnComponent,
    InformationButtonComponent,
    ModuleTypePipe,
  ],
  standalone: true,
})
export class CompanyProjectComponent {
  @HostBinding('class') class =
    'flex-1 bg-white shadow-md hover:shadow-lg min-h-[150px] border border-gray-100 pt-4';

  public genModuleFormErr: HttpErrorResponse | undefined;
  public isAddingModule: boolean = false;
  public moduleForm: FormGroup;
  public availabilityOptions = [
    {
      label: 'Available',
      value: 'available',
    },
    {
      label: 'Unavailable',
      value: 'unavailable',
    },
  ];
  public moduleOptions: DropdownOption[] = [
    {
      label: 'Accounting',
      value: 'accounting',
      disabled: false,
    },
    {
      label: 'Parts',
      value: 'parts',
    },
    {
      label: 'Payroll',
      value: 'payroll',
      disabled: false,
    },
    {
      label: 'Sales',
      value: 'sales',
      disabled: false,
    },
    {
      label: 'Service',
      value: 'service',
    },
    {
      label: 'F&I',
      value: 'fi',
    },
    {
      label: 'Infrastructure',
      value: 'infrastructure',
    },
  ];
  public selectedModule: DropdownOption | undefined;
  public datePattern =
    /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]\d{4}$/;

  @Input() isParentCompany: boolean;
  @Input() company: ICompanyReadFull | undefined;
  @Input() isLoading: boolean = false;
  @Input() modules: ICoreModule[] | undefined = [];
  @Input() companyId: string | Types.ObjectId | undefined;
  @Input() canAddModule: boolean = false;
  @Input() canEditModuleDetails: boolean = false;
  @Input() canSeeInternalDetails: boolean = false;
  @Input() canAssignModuleUser: boolean = false;
  @Input() internalView: boolean = false;

  @Output() emitAddModule: EventEmitter<CoreModuleCreateDto> =
    new EventEmitter<CoreModuleCreateDto>();
  @Output() emitUpdateModuleDetails: EventEmitter<
    UpdateEmission<CoreModuleUpdateDto>
  > = new EventEmitter<UpdateEmission<CoreModuleUpdateDto>>();
  @Output() emitUpdateSubmoduleDetails: EventEmitter<
    UpdateEmission<SubmoduleUpdateDto>
  > = new EventEmitter<UpdateEmission<SubmoduleUpdateDto>>();
  @Output() emitAssignUserToModule: EventEmitter<CoreModuleAddUserDto> =
    new EventEmitter<CoreModuleAddUserDto>();

  @ViewChild('addModuleModal') addModuleModal: ModalComponent | undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.moduleForm = this.fb.group({
      moduleType: ['', Validators.required],
      name: [''],
      desc: [''],
    });
  }

  openModuleModal() {
    this.addModuleModal?.open();
  }

  markSelectModuleAsTouched() {
    this.moduleForm.get('moduleType')?.markAsTouched();
  }

  selectModuleType(option: DropdownOption) {
    this.selectedModule = option;
    this.moduleForm.get('moduleType')?.setValue(option.value);
    this.markSelectModuleAsTouched();
  }

  cancel() {
    this.moduleForm.reset();
    this.selectedModule = undefined;
    this.addModuleModal?.close();
  }

  addModuleFailed(err: HttpErrorResponse) {
    this.isAddingModule = false;
    this.genModuleFormErr = err;
  }

  addModuleSucceeded() {
    this.moduleForm.reset();
    this.selectedModule = undefined;
    this.isAddingModule = false;
    this.addModuleModal?.close();
  }

  addModule() {
    if (this.moduleForm.invalid) {
      return;
    }
    this.isAddingModule = true;
    const dto: CoreModuleCreateDto = {
      companyId: this.companyId as string,
      moduleType: this.moduleForm.get('moduleType')?.value,
      name: this.moduleForm.get('name')?.value,
      desc: this.moduleForm.get('desc')?.value,
    };
    this.emitAddModule.emit(dto);
  }

  updateModuleDetails(
    event: ICoreModuleUpdateEvent,
    editable: EditableSelectComponent | EditableTextComponent,
  ) {
    const dto: CoreModuleUpdateDto = {
      companyId: this.companyId as string,
      moduleId: event.moduleId as string,
      [event.field]: event.value,
    };
    this.emitUpdateModuleDetails.emit({ dto, editable });
  }

  updateSubmoduleDetails(
    event: ISubmoduleUpdateEvent,
    editable: EditableSelectComponent | EditableTextComponent,
  ) {
    if (event.field === 'dueDate') {
      event.value = startOfDay(parseISO(event.value as string));
    }
    if (event.field === 'isAvailable') {
      event.value = event.value === 'available' ? true : false;
    }
    const dto: SubmoduleUpdateDto = {
      companyId: this.companyId as string,
      moduleId: event.moduleId as string,
      submoduleId: event.submoduleId,
      [event.field]: event.value,
    };
    this.emitUpdateSubmoduleDetails.emit({ dto, editable });
  }

  assignUserToModule(module: ICoreModule, option: DropdownOption) {
    const dto: CoreModuleAddUserDto = {
      companyId: this.companyId as string,
      moduleId: module._id as unknown as string,
      userId: option.value as string,
    };
    this.emitAssignUserToModule.emit(dto);
  }

  goToModule(module: ICoreModule, fragment?: string) {
    if (fragment) {
      this.router
        .navigate(['core', this.companyId, 'module', module._id], { fragment })
        .then(() => {
          setTimeout(() => {
            const targetElement = document.getElementById(fragment);
            if (targetElement) {
              targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
              });
            }
          }, 200);
        });
      return;
    }

    this.router.navigate(['core', this.companyId, 'module', module._id]);
    return;
  }
}
