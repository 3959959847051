import { IsDefined, IsNotEmpty, IsString } from 'class-validator';

export interface IFileUploadReference {
  _id: string;
  companyId: string;
  moduleId: string;
  key: string;
  name: string;
  dateUploaded: Date;
  questionKey: string;
}

export class FileUploadDto {
  @IsString()
  @IsNotEmpty()
  name: string;
  @IsString()
  @IsNotEmpty()
  companyId: string;
  @IsString()
  @IsNotEmpty()
  moduleId: string;
  @IsString()
  @IsNotEmpty()
  submoduleId: string;
  @IsString()
  @IsNotEmpty()
  field: string;
  file: Buffer;
}

export class FileGetDto {
  @IsString()
  @IsNotEmpty()
  companyId: string;
  @IsString()
  @IsNotEmpty()
  moduleId: string;
  @IsString()
  @IsNotEmpty()
  fileId: string;
}

export class FileDeleteDto {
  @IsString()
  @IsNotEmpty()
  companyId: string;
  @IsString()
  @IsNotEmpty()
  moduleId: string;
  @IsString()
  @IsNotEmpty()
  submoduleId: string;
  @IsString()
  @IsNotEmpty()
  fileId: string;
  @IsString()
  @IsNotEmpty()
  field: string;
}

export interface IFile extends IFileUploadReference {
  file: Buffer;
}
