import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { ResponseOptions } from '@dominion/interfaces';
import { SharedModule } from '../../shared/shared.module';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';

@Component({
  selector: 'dominion-input-dropdown',
  standalone: true,
  imports: [CommonModule, SharedModule, DiscoveryNextBtnDirective],
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.css'],
})
export class InputDropdownComponent
  implements ResponseInput, OnInit, OnChanges
{
  @Input({ required: true }) data: any;
  @Input({ required: true }) options: ResponseOptions[];

  @Output() response: EventEmitter<any> = new EventEmitter<any>();

  selectedOption: ResponseOptions | undefined | null;

  constructor() {}

  ngOnInit(): void {
    this.selectedOption = this.options.find(
      (option) => option.value === this.data,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.selectedOption = this.options.find(
        (option) => option.value === changes['data'].currentValue,
      );
    }
  }

  selectResponse(option: ResponseOptions) {
    this.selectedOption = option;
  }

  saveResponse() {
    if (this.selectedOption) {
      this.response.emit(this.selectedOption.value);
    }
  }

  handleErr(err: any): void {
    console.error(err);
  }

  handleSuccess(res: any): void {
    return;
  }
}
