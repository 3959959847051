import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';

@Component({
  selector: 'dominion-input-date',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css'],
})
export class InputDateComponent implements ResponseInput {
  @Input({ required: true }) data: string;

  @Output() response: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleErr(err: any): void {
    throw new Error('Method not implemented.');
  }

  handleSuccess(res: any): void {
    throw new Error('Method not implemented.');
  }
}
